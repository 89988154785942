<template>
  <section class="info-section">
    <div class="section__wrapper">
      <div class="section__container">
        <v-row>
          <v-col cols="12" md="6" lg="6" class="text-left">
            <v-icon class="info__icon">mdi-heart</v-icon>
            <p class="info__sub-title"><span class="emp">DIGU</span> Cares</p>
            <p class="info__title">
              The Core Foundation<br />
              Of DIGU
            </p>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="description__wrapper">
            <p class="info__description text-left">
              We strongly believe in nurturing talent to pave the way for
              thriving careers in the dynamic realm of technology. We want to
              inspire every Nepali including people in the underprivileged
              regions to dream and achieve their goals. We aim to enable and
              empower these individuals to seize opportunities in the global
              market.
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'InfoSection',
};
</script>

<style scoped>
.info-section {
  background-color: var(--light-1);
}

.section__wrapper {
  /* background: url("@/assets/img/cares_hero_bg.svg"); */
  background-color: var(--gray-3);
  background-repeat: repeat no-repeat;
  min-height: 20rem;
  display: grid;
  align-items: center;
  padding-block: 2rem;
}

.info__icon {
  color: var(--accent-red);
}

.info__sub-title {
  font-size: 1.5rem;
  color: var(--accent-red);
  font-family: 'DM Sans Regular', sans-serif;
}

.info__sub-title .emp {
  font-family: 'DM Sans Bold', sans-serif;
}

.info__title {
  font-family: 'DM Sans SemiBold', sans-serif;
  font-size: 2.5rem;
  line-height: 115%;
}

.description__wrapper {
  display: grid;
  align-items: end;
}
</style>
