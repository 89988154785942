import axios from 'axios';
axios.defaults.timeout = 10000;

/**
 * Fetch a list of events
 */
const listAllEvents = async () => {
  const apiUrl = process.env.VUE_APP_API_URL;
  const events = await axios
    .get(`${apiUrl}/v1/public/events`)
    .then((response) => {
      return {
        status: response.status,
        data: response.data.data,
      };
    });

  return events;
};

const showEvent = async (id) => {
  const apiUrl = process.env.VUE_APP_API_URL;
  const event = await axios
    .get(`${apiUrl}/v1/public/events/${id}`)
    .then((response) => {
      return {
        status: response.status,
        data: response.data.data,
      };
    });

  return event;
};

export default {
  listAllEvents,
  showEvent,
};
