<template>
  <footer class="footer--global">
    <keep-alive>
      <div class="footer__top">
        <div class="section__container">
          <v-row class="footer__top-row">
            <v-col
              lg="4"
              md="12"
              sm="12"
              class="section__col footer__top-section logo-section ps-0"
            >
              <v-img
                class="logo"
                eager
                :src="require('@/assets/img/Digu_Logo_White.webp')"
                :width="200"
              ></v-img>
              <p class="description m400">Talent beyond boundaries</p>
            </v-col>
            <v-col lg="4" md="12" sm="12" cols="12" class="footer__top-section">
              <p class="footer__section-title outfit medium">Explore</p>
              <v-row class="section__row">
                <v-col class="section__col">
                  <ol class="footer__section-list">
                    <li class="footer__section-link">
                      <a class="m500" href="/about">About Us</a>
                    </li>
                    <li class="footer__section-link">
                      <a class="m500" href="/care">DIGU Cares</a>
                    </li>
                    <li class="footer__section-link">
                      <a class="m500" href="/for-hiring-companies"
                        >Why DIGU For Businesses</a
                      >
                    </li>
                    <li class="footer__section-link">
                      <a class="m500" href="/hire-a-professional"
                        >Hire a Professional</a
                      >
                    </li>
                    <li class="footer__section-link">
                      <a class="m500" href="/outsource-your-project"
                        >Outsource Your Project</a
                      >
                    </li>
                    <li class="footer__section-link">
                      <a class="m500" href="/events">Events</a>
                    </li>
                    <li class="footer__section-link">
                      <a class="m500" href="/trainings-and-certifications"
                        >Trainings and Certifications</a
                      >
                    </li>
                  </ol>
                </v-col>
                <v-col class="section__col">
                  <ol class="footer__section-list">
                    <li class="footer__section-link">
                      <a class="m500" href="/why-digu-for-career-opportunities"
                        >Why DIGU For Career Opportunities</a
                      >
                    </li>
                    <li class="footer__section-link">
                      <a class="m500" href="/digu-central">DIGU Central</a>
                    </li>
                    <li class="footer__section-link">
                      <a class="m500" href="/job-placement-program"
                        >Learn About Our Job Placement Program</a
                      >
                    </li>
                    <!-- TODO: enable this after the page is fixed -->
                    <!-- <li class="footer__section-link">
                      <a class="m500" href="/verified-badges"
                        >Verified Badges</a
                      >
                    </li> -->

                    <li class="footer__section-link">
                      <a class="m500" href="/contact">Contact</a>
                    </li>
                    <li class="footer__section-link">
                      <a class="m500" href="/join-our-talent-pool"
                        >Join Our Talent Pool</a
                      >
                    </li>
                  </ol>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              lg="4"
              md="12"
              sm="12"
              cols="12"
              class="footer__top-section section__col"
            >
              <p class="footer__section-title outfit medium">Reach Us</p>
              <p class="hq-address m400 mb-3">
                <span class="bold">DIGU HQ</span>, 2307 Fenton Pkwy, Ste 107
                POB788,
                <br />
                San Diego, CA 92108-4746<br />
                United States <br />
                <span class="email mb-5 m400">
                  <span class="text-light">Phone:</span>
                  <a href="tel:+1 (888) 744-1094" class="mail-link"
                    >+1 (888) 744-1094</a
                  >
                </span>
              </p>
              <p class="central-address m400 manrope">
                <span class="bold">DIGU Central</span>, 92/9 Sai Marg, Bansbari
                <br />
                Kathmandu, 44600, Nepal
              </p>

              <br />
              <p class="footer__section-title outfit medium mb-2">
                Get in Touch
              </p>
              <p class="email mb-5 m400">
                <a href="mailto:info@digu.one" class="mail-link"
                  >info@digu.one</a
                >
              </p>

              <keep-alive>
                <p class="social-links">
                  <a
                    href="https://www.instagram.com/digu.official/"
                    target="_blank"
                  >
                    <v-img
                      :src="require('@/assets/img/instagram.svg')"
                      eager
                      width="28"
                    ></v-img
                  ></a>
                  <a
                    href="https://www.linkedin.com/company/digu-official/"
                    target="_blank"
                    ><v-img
                      :src="require('@/assets/img/linkedin.svg')"
                      eager
                      width="28"
                    ></v-img
                  ></a>
                  <a
                    href="https://www.facebook.com/digu.official/"
                    target="_blank"
                    ><v-img
                      :src="require('@/assets/img/facebook.svg')"
                      eager
                      width="28"
                    ></v-img
                  ></a>
                  <a href="https://x.com/digu_official" target="_blank"
                    ><v-img
                      :src="require('@/assets/img/x-twitter.svg')"
                      eager
                      width="28"
                    ></v-img
                  ></a>
                  <a href="https://discord.gg/zzZj8Qzeu8" target="_blank"
                    ><v-img
                      :src="require('@/assets/img/discord.svg')"
                      eager
                      width="28"
                    ></v-img
                  ></a>
                </p>
              </keep-alive>
            </v-col>
          </v-row>
        </div>
      </div>
    </keep-alive>
    <div class="footer__bottom">
      <div class="section__container">
        <v-row class="footer__bottom-row">
          <v-col cols="12" sm="6" class="pa-0 footer__bottom-col">
            <p class="footer__copyright m500">
              &copy; 2023 - {{ getCurrentYear }} DIGU &#124; All Rights Reserved
            </p>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pa-0 footer__bottom-col d-sm-flex justify-end"
          >
            <a href="/privacy-policy">Privacy Policy</a>
          </v-col>
        </v-row>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'GlobalFooter',
  computed: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
.footer--global {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer__top {
  width: 100vw;
  display: flex;
  background-color: var(--dark-2);
  padding-block: 3rem;
}

@media only screen and (min-width: 1280px) {
  .footer__top {
    padding-inline: 0px;
  }
}

.footer__top-row {
  max-width: 1400px;
  margin-inline: auto;
}

@media only screen and (min-width: 2000px) {
  .footer__top-row {
    max-width: 1920px;
  }
}

@media only screen and (min-width: 1366px) {
  .logo-section {
    background-image: url('@/assets/img/map.svg');
    background-size: contain;
    background-position: center;
  }
}

.description {
  color: var(--light-1);
  font-size: 1rem;
}

@media only screen and (min-width: 1280px) {
  .description {
    width: 24rem;
  }
}

.footer__top-section {
  text-align: left;
  padding-inline: 0px;
}

@media only screen and (min-width: 1200px) {
  .footer__top-section {
    padding-inline: 1rem;
  }
}

.footer__section-title {
  color: var(--light-1);
  font-size: 1.3rem;
  line-height: normal;
  margin-bottom: 1.3rem;
}

.footer__section-list {
  list-style-type: none;
}

.footer__section-link {
  margin-bottom: 0.75rem;
  text-decoration: none;
  line-height: normal;
}

.footer__section-link:last-child {
  margin-bottom: 0px;
}

.footer__section-link > a,
.footer__bottom a {
  text-decoration: none;
  color: var(--light-1);
}

.footer__section-link > a:hover {
  text-decoration: none;
  color: var(--secondary-color);
}

.central-address,
.hq-address {
  color: var(--light-1);
}

.email a {
  color: var(--primary-color-lighten);
  text-decoration: none;
}

.social-links {
  display: flex;
  align-items: center;
}

.social-links a {
  margin-right: 1rem;
}

.footer__bottom {
  width: 100vw;
  display: flex;
  background-color: var(--dark-4);
  padding: 2rem;
}

.footer__bottom-col {
  padding: 0px;
  font-size: 0.85rem;
}

@media only screen and (min-width: 900px) {
  .footer__bottom-col {
    font-size: 0.95rem;
  }
}

.footer__copyright {
  max-width: 1400px;
  margin-inline: auto;
  text-align: center;
  color: var(--light-1);
}

@media only screen and (min-width: 1280px) {
  .footer__copyright {
    text-align: left;
  }
}

@media only screen and (min-width: 2000px) {
  .footer__copyright {
    max-width: 1920px;
  }
}

/* Temporary Logo Section */
.logo {
  color: var(--light-1);
  margin-bottom: 1.3rem;
}

.logo > h3 {
  font-size: 2rem;
  line-height: normal;
}

.logo > p {
  font-size: 1rem;
  line-height: normal;
}
</style>
