<template>
  <section class="professional-development-section">
    <div class="section__container">
      <v-row class="section__row">
        <v-col cols="12" sm="12" md="5" lg="5" class="section__col">
          <p class="section__title text-left">
            Professional <br />
            Development Events
          </p>
          <p class="section__description text-left">
            We empower our local partners to organize professional development
            events which include effective communication workshops,
            resume-building workshops, and more.
          </p>
        </v-col>
        <v-col cols="12" sm="12" md="7" lg="7" class="section__col">
          <developer-feedback></developer-feedback>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import DeveloperFeedback from '@/components/DeveloperFeedback.vue';
export default {
  name: 'ProfessionalDevelopmentSection',
  components: {
    'developer-feedback': DeveloperFeedback,
  },
};
</script>

<style scoped>
.professional-development-section {
  padding-block: 2rem 4rem;
}

@media only screen and (min-width: 960px) {
  .professional-development-section {
    padding-block: 4rem;
    padding-inline: 4rem;
  }
}

.section__title {
  font-family: 'DM Sans Bold', sans-serif;
  font-size: 2.5rem !important;
  line-height: 120% !important;
  margin-bottom: 1rem;
}

.section__description {
  font-family: 'Manrope Medium', sans-serif !important;
  font-size: 1.25rem !important;
  line-height: 200% !important;
}

.section__img {
  border-radius: 2rem;
  aspect-ratio: 16/9;
  width: 100%;
}

@media only screen and (min-width: 960px) {
  .section__img {
    width: 80%;
  }
}
</style>
