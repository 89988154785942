<template>
  <div :class="classList">
    <vue-hcaptcha
      :sitekey="hcaptchaSiteKey"
      @error="onError"
      @verify="onVerify"
      @expired="onExpired"
      @open="onOpen"
      @closed="onClosed"
      @reset="onReset"
      @rendered="onRendered"
      @executed="onExecuted"
      :size="size"
    ></vue-hcaptcha>
  </div>
</template>

<script>
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
export default {
  name: "HCaptchaWidget",
  data: () => ({
    hcaptchaSiteKey: process.env.VUE_APP_HCAPTCHA_SITE_KEY,
  }),
  props: {
    classList: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: "default",
    },
  },
  components: {
    "vue-hcaptcha": VueHcaptcha,
  },
  emits: [
    "error",
    "verify",
    "expired",
    "open",
    "closed",
    "reset",
    "rendered",
    "executed",
  ],
  methods: {
    onError(error) {
      this.$emit("error", error);
    },
    onVerify(token_, ekey_) {
      this.$emit("verify", { token: token_, eKey: ekey_ });
    },
    onExpired() {
      this.$emit("expired");
    },
    onOpen() {
      this.$emit("open");
    },
    onClosed() {
      this.$emit("closed");
    },
    onReset() {
      this.$emit("reset");
    },
    onRendered() {
      this.$emit("rendered");
    },
    onExecuted() {
      this.$emit("executed");
    },
  },
};
</script>

<style>
</style>
