<template>
  <section class="section--benefits">
    <div class="section__container">
      <!-- ROW 1 -->
      <v-row class="section__row">
        <v-col class="section__col" cols="12" sm="6" md="6" lg="4" xl="4">
          <keep-alive>
            <v-img
              eager
              :src="require('@/assets/img/cost-effective-solutions.webp')"
              class="section__img"
            ></v-img>
          </keep-alive>
        </v-col>
        <v-col
          class="section__col info__col"
          cols="12"
          sm="6"
          md="6"
          lg="8"
          xl="8"
        >
          <div class="col__info">
            <p class="section__title-2 dms600">Cost-Effective Solutions</p>
            <p class="section__description-2 m500">
              With Nepal's lower living costs compared to the developed world,
              we can provide skilled professionals at a more
              budget-friendly rate, enabling cost savings for your company.
            </p>
          </div>
        </v-col>
      </v-row>

      <!-- Row 2 -->
      <v-row class="section__row">
        <v-col
          class="section__col order-last order-sm-first info__col"
          cols="12"
          sm="6"
          md="6"
          lg="8"
          xl="8"
        >
          <div class="col__info">
            <p class="section__title-2 dms600">
              Effortless Payroll & Compliance
            </p>
            <p class="section__description-2 m500">
              Rest assured, we handle all employee payroll and ensure full
              compliance with local and international labor laws. Additionally,
              employee benefits such as insurance and retirement are taken care
              of, easing your administrative burden.
            </p>
          </div>
        </v-col>
        <v-col class="section__col">
          <keep-alive>
            <v-img
              eager
              :src="
                require('@/assets/img/effort-less-payroll-and-compliance.webp')
              "
              class="section__img"
            ></v-img>
          </keep-alive>
        </v-col>
      </v-row>

      <!-- Row 4 -->
      <v-row class="section__row">
        <v-col class="section__col" cols="12" sm="6" md="6" lg="4" xl="4">
          <keep-alive>
            <v-img
              eager
              :src="
                require('@/assets/img/streamlined-recruitment-process.webp')
              "
              class="section__img"
            ></v-img>
          </keep-alive>
        </v-col>
        <v-col
          class="section__col info__col"
          cols="12"
          sm="6"
          md="6"
          lg="8"
          xl="8"
        >
          <div class="col__info">
            <p class="section__title-2 dms600">
              Streamlined Recruitment Process
            </p>
            <p class="section__description-2 m500">
              Searching for the right talents can be time-consuming,
              especially for offshore resources. Let us simplify the process for
              you by presenting verified professionals with technical expertise,
              excellent communication skills, ability to perform well under
              pressure, and ready for interviews.
            </p>
          </div>
        </v-col>
      </v-row>

      <!-- Row 4 -->
      <v-row class="section__row">
        <v-col
          class="section__col order-last order-sm-first info__col"
          cols="12"
          sm="6"
          md="6"
          lg="8"
          xl="8"
        >
          <div class="col__info">
            <p class="section__title-2 dms600">Round-the-Clock Availability</p>
            <p class="section__description-2 m500">
              Our highly motivated professionals are available 24/5, catering to
              both onshore and offshore work hours, according to your project
              requirements.
            </p>
          </div>
        </v-col>
        <v-col class="section__col">
          <keep-alive>
            <v-img
              eager
              :src="require('@/assets/img/round-the-clock-availability.webp')"
              class="section__img"
            ></v-img>
          </keep-alive>
        </v-col>
      </v-row>

      <!-- Row 4 -->
      <v-row class="section__row">
        <v-col class="section__col" cols="12" sm="6" md="6" lg="4" xl="4">
          <keep-alive>
            <v-img
              eager
              :src="require('@/assets/img/flexible-scalability.webp')"
              class="section__img"
            ></v-img>
          </keep-alive>
        </v-col>
        <v-col
          class="section__col info__col"
          cols="12"
          sm="6"
          md="6"
          lg="8"
          xl="8"
        >
          <div class="col__info">
            <p class="section__title-2 dms600">Flexible Scalability</p>
            <p class="section__description-2 m500">
              Seamlessly scale your technology team with DIGU to meet your
              company's evolving needs. Our agile approach enables swift
              workforce adjustments without incurring substantial expenses.
            </p>
          </div>
        </v-col>
      </v-row>

      <!-- Row 4 -->
      <v-row class="section__row">
        <v-col
          class="section__col order-last order-sm-first info__col"
          cols="12"
          sm="6"
          md="6"
          lg="8"
          xl="8"
        >
          <div class="col__info">
            <p class="section__title-2 dms600">Accelerated Time to Market</p>
            <p class="section__description-2 m500">
              By leveraging our professionals based in Nepal, and capitalizing on the 24-hour
              work cycle and skilled workforce availability, we help expedite
              your product development cycles, ensuring faster time to market
              for your projects.
            </p>
          </div>
        </v-col>
        <v-col class="section__col">
          <keep-alive>
            <v-img
              eager
              :src="require('@/assets/img/accelerated-time-to-market.webp')"
              class="section__img"
            ></v-img>
          </keep-alive>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BenefitsSection',
};
</script>

<style scoped>
.section--benefits {
  background-color: var(--secondary-color-lighten);
  padding-block: 4rem;
}

@media only screen and (min-width: 700px) {
  .section--benefits {
    padding-block: 2rem;
  }
}

.section__header {
  margin-bottom: 3rem;
}

.section__subtitle {
  font-size: 1.25rem;
  color: var(--accent-red);
  text-align: left;
}

@media only screen and (min-width: 1280px) {
  .section__subtitle {
    font-size: 1.5rem;
  }
}

.section__title {
  font-size: 1.25rem;
  text-align: left;
}

@media only screen and (min-width: 1280px) {
  .section__title {
    font-size: 1.75rem;
  }
}

.section__row {
  margin-bottom: 3rem;
}

@media only screen and (min-width: 1280px) {
  .section__row {
    margin-bottom: auto;
  }
}

.section__col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section__img {
  height: 250px;
  width: 100%;
}

@media only screen and (min-width: 1280px) {
  .section__img {
    width: 100%;
  }
}

.section__title {
  font-size: 1.3rem;
  text-align: left;
}

.col__info {
  text-align: left;
}

@media only screen and (min-width: 1280px) {
  .col__info {
    text-align: left;
    width: 80%;
  }
}

@media only screen and (min-width: 1280px) {
  .info__col:nth-child(odd) {
    justify-content: flex-start;
  }

  .info__col:nth-child(even) {
    justify-content: flex-end;
  }
}

.section__title-2 {
  line-height: 120%;
}

@media only screen and (min-width: 1200px) {
  .section__title-2 {
    margin-bottom: 1rem;
  }
}
</style>
