<template>
  <section class="programs-section">
    <div class="section__container">
      <v-row class="section__row">
        <v-col class="section__col" cols="12" sm="12" md="8" lg="8">
          <keep-alive>
            <v-img
              eager
              cover
              :src="require('@/assets/img/programs-section.webp')"
              :lazy-src="require('@/assets/img/lazy-img.jpg')"
              class="section__img"
            ></v-img>
          </keep-alive>
        </v-col>
        <v-col class="section__col" cols="12" sm="12" md="4" lg="4">
          <div class="programs__wrapper">
            <p class="section__title">Our Programs</p>

            <p class="program">Internship Opportunities</p>
            <p class="program">Mentorship Programs</p>
            <p class="program">DIGUTalks - Techknowledgy</p>
            <p class="program">Scholarships</p>
            <p class="program">Entry Level Opportunities</p>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProgramsSection',
};
</script>

<style scoped>
.programs-section {
  background: var(--light-1);
  padding-block: 5rem;
}

.section__img {
  border-radius: 2rem;
  aspect-ratio: 16/9;
  width: 100%;
}

@media only screen and (min-width: 960px) {
  .section__img {
    width: 80%;
  }
}

.section__title {
  font-family: 'DM Sans Bold', sans-serif;
  font-size: 2.5rem !important;
  line-height: 120% !important;
  margin-bottom: 1rem;
  text-align: center !important;
}

@media only screen and (min-width: 960px) {
  .section__title {
    text-align: left !important;
  }
}

.programs__wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.program {
  font-family: 'Manrope Medium', sans-serif;
  font-size: 1.25rem;
  margin-top: 1rem;
  text-align: left;
}
</style>
