import axios from "axios";
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] = "application/json";

// ? Send a Message
const sendEmail = async (message) => {
  const apiUrl = process.env.VUE_APP_API_URL;
  const messageStatus = await axios
    .post(`${apiUrl}/v1/public/contact`, message)
    .then((response) => {
      return response;
    });

  return messageStatus;
};

export default {
  sendEmail,
};
