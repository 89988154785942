<template>
  <div class="for-job-seekers" id="view">
    <hero-section :title="title" :subTitle="subTitle" :showBlueWaves3="true" />
    <benefits-section />
    <client-stories :hasPB="true"></client-stories>
    <developer-hiring-section />
    <neu-footer-sektion />
  </div>
</template>

<script>
import HeroSection from '@/components/partials/HeroSection.vue';
import BenefitsSection from './Sections/BenefitsSection.vue';
import DeveloperHiringSection from './Sections/DeveloperHiringSection.vue';
import NeuFooterSektion from '@/views/about/Sections/NeuFooterSektion.vue';
import ClientStories from './Sections/ClientStories.vue';
export default {
  name: 'ForHiringCompanies',
  components: {
    'hero-section': HeroSection,
    'benefits-section': BenefitsSection,
    'developer-hiring-section': DeveloperHiringSection,
    'neu-footer-sektion': NeuFooterSektion,
    'client-stories': ClientStories,
  },
  data: () => ({
    title: `<span class="title--special" style="display: flex;align-items: center; flex-wrap: wrap;"><span class="title__text">Affordable</span>
          <span class="title__dot"
            >●</span
          >
          <span class="title__text">Scalable</span>
          <span class="title__dot"
            >●</span
          >
          <span class="title__text">Accessible</span></span>`,
    subTitle: `Why DIGU for Businesses <br /> <span style="color: black; font-family: 'Outfit Regular'; font-size: 1.25rem">Our Solutions are</span>`,
    imageName: 'for-hiring-companies-hero.webp',
  }),
  created() {
    document.getElementById('loaderSection').classList.add('d-none');
  },
};
</script>
