<template>
  <div class="home" id="view">
    <hero-section />
    <aim-section />
    <stories-section />
    <carousel-section />
  </div>
</template>

<script>
// Global Components

// Local Components
import AimSectionVue from './Sections/AimSection.vue';
import CarouselSectionVue from './Sections/CarouselSection.vue';
import HeroSectionVue from './Sections/HeroSection.vue';
import StoriesSection from './Sections/StoriesSection.vue';
export default {
  name: 'HomeIndex',
  components: {
    'hero-section': HeroSectionVue,
    'aim-section': AimSectionVue,
    'carousel-section': CarouselSectionVue,
    'stories-section': StoriesSection,
  },
  created() {
    document.getElementById('loaderSection').classList.add('d-none');
  },
};
</script>

<style scoped>
.home {
  width: 100vw;
  overflow-x: hidden;
}
</style>
