import axios from "axios";
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

// ? Get all Job Openings
const listAllJobOpenings = async () => {
  const apiUrl = process.env.VUE_APP_API_URL;
  const jobOpenings = await axios
    .get(`${apiUrl}/v1/public/job-listings`)
    .then((response) => {
      return {
        status: response.status,
        data: response.data.data,
      };
    });

  return jobOpenings;
};

// ? Get single Job Openings
const showJobOpenings = async (id) => {
  const apiUrl = process.env.VUE_APP_API_URL;
  const jobOpenings = await axios
    .get(`${apiUrl}/v1/public/job-listings/${id}`)
    .then((response) => {
      return {
        status: response.status,
        data: response.data.data,
      };
    });

  return jobOpenings;
};
export default {
  listAllJobOpenings,
  showJobOpenings,
};
