<template>
  <header class="header--global" :class="{ fixed: scrolling }">
    <div class="section__container">
      <v-row class="header__row">
        <v-col class="header__image-container" sm="6" md="6" lg="3">
          <a href="/"
            ><keep-alive
              ><img
                class="header__logo"
                :src="
                  require('@/assets/img/Digu_Logo_Primary.webp')
                " /></keep-alive
          ></a>
        </v-col>
        <v-col class="header__menu-container pe-0">
          <desktop-menu />
          <mobile-menu />
        </v-col>
      </v-row>
    </div>
  </header>
</template>

<script>
import DesktopMenuVue from '@/components/partials/DesktopMenu.vue';
import MobileMenuVue from '@/components/partials/MobileMenu.vue';
export default {
  name: 'GlobalHeader',
  components: {
    'desktop-menu': DesktopMenuVue,
    'mobile-menu': MobileMenuVue,
  },
  data: () => ({
    screen: `${window.screen.width}x${window.screen.height}`,
    shouldShowScreenSize: process.env.NODE_ENV == 'development',
    scrolling: false,
  }),
  methods: {
    floatHeader() {
      if (window.scrollY != 0) {
        this.scrolling = true;
      }
      if (window.scrollY == 0) {
        this.scrolling = false;
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.floatHeader);
  },
  unmounted() {
    document.removeEventListener('scroll', this.floatHeader);
  },
};
</script>

<style scoped>
/* ---- */
.header--global {
  padding-block: 2rem;
  background-color: var(--light-1);
  transition: all 100ms ease-in-out;
}

.fixed {
  padding-inline: 1rem !important;
  z-index: 1000;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 8px 16px #00000011;
}

@media only screen and (min-width: 1500px) {
  .header--global {
    padding-block: 1rem;
    padding-inline: px;
    background-color: var(--light-1);
  }

  .header--global.fixed {
    padding-block: 0.5rem !important;
  }
}

.header__row {
  max-width: 1400px;
  margin-inline: auto;
}

@media only screen and (min-width: 2000px) {
  .header__row {
    max-width: 1920px;
  }
}

.header__image-container {
  display: flex;
  align-items: center;
}

.header__logo {
  height: 2.25rem;
  transition: height 50ms ease-in-out;
}

@media only screen and (min-width: 1280px) {
  .header__logo {
    height: 3rem;
  }

  .fixed .header__row .header__image-container .header__logo {
    height: 2.5rem;
  }
}
</style>
