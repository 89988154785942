<template>
  <div class="job-openings">
    <hero-section :title="title" :showWhiteWave="showWhiteWave" />
    <div class="section__container">
      <div class="loader" v-if="UIController.loader.contentLoading">
        <v-progress-circular indeterminate></v-progress-circular>
        <p class="mt-5">Hold On! while we fetch the available job openings.</p>
      </div>
      <div v-else>
        <v-card elevation="0" class="error-card" v-if="!jobOpening.id">
          <v-card-title class="error-card__title text-left dms700"
            >This Job Opening is Not Available</v-card-title
          >
          <v-card-text>
            <h3 class="error-card__text-title text-left m500">
              Hmm! Looks like the job opening you are trying to access does not
              exist.
            </h3>
            <p class="error-card__text text-left m500">
              You can always look through other job openings available right
              now.
            </p>
          </v-card-text>
        </v-card>
        <div v-else>
          <div class="job-post">
            <div class="job-post__category-container text-left">
              <v-chip
                label
                size="small"
                :color="jobOpening.jobType.color"
                class="mr-2"
                >{{ jobOpening.jobType.name }}</v-chip
              >
              <v-chip
                label
                size="small"
                :color="jobOpening.category.color"
                class="mr-2"
                >{{ jobOpening.category.name }}</v-chip
              >
            </div>
            <div class="job-post__tags text-left">
              <v-chip
                v-for="hardSkill in jobOpening.hardSkills"
                :key="hardSkill.id"
                :color="hardSkill.color"
                class="job-post__skill-chip"
                size="small"
              >
                {{ hardSkill.label }}
              </v-chip>
            </div>
            <p class="job-post__sub-title dms600">About Job</p>
            <p
              class="job-post__description text-left"
              v-html="jobOpening.description"
            ></p>
            <p class="job-post__sub-title dms600">Requirements</p>
            <ul class="job-post__list">
              <li
                class="list__item text-left"
                v-for="(value, index) in jobOpening.requirements"
                :key="index"
              >
                {{ value }}
              </li>
            </ul>
            <p class="job-post__sub-title dms600">Responsibilities</p>
            <ul class="job-post__list">
              <li
                class="list__item text-left"
                v-for="(value, index) in jobOpening.responsibilities"
                :key="index"
              >
                {{ value }}
              </li>
            </ul>
            <p class="job-post__sub-title dms600" v-if="jobOpening.benefits">
              Benefits
            </p>
            <ul class="job-post__list">
              <li
                class="list__item text-left"
                v-for="(value, index) in jobOpening.benefits"
                :key="index"
              >
                {{ value }}
              </li>
            </ul>

            <div
              class="job-post__description text-left"
              v-html="jobOpening.footer"
            ></div>

            <div class="apply-container text-left">
              <v-btn
                class="job-opening__apply-button my-3"
                color="#2ea3f2"
                elevation="0"
                :href="`/job-application?jobId=${jobOpening.id}`"
                >Apply Now</v-btn
              >
            </div>
            <div
              class="job-opening__contact-card"
              :style="{
                'border-top-color': jobOpening.jobContact.color
                  ? jobOpening.jobContact.color
                  : '#000',
              }"
              v-if="hasJobContact"
            >
              <v-row class="contact-card">
                <v-col cols="8">
                  <p class="job-opening__contact-title text-left m600 mb-2">
                    Contact
                  </p>
                  <p class="contact-card__name text-left">
                    <span class="contact-card__text">{{
                      jobOpening.jobContact.username
                    }}</span>
                  </p>
                  <p class="contact-card__mail text-left d-none d-md-block">
                    <a
                      :href="`mailto:${jobOpening.jobContact.email}`"
                      class="contact-card__link"
                      >{{ jobOpening.jobContact.email }}</a
                    >
                  </p>
                  <p class="contact-card__mail text-left d-md-none">
                    <v-btn
                      class="mt-3 contact__button m700"
                      color="#004871"
                      :href="`mailto:${jobOpening.jobContact.email}`"
                      elevation="0"
                      >Send Email</v-btn
                    >
                  </p>
                </v-col>
                <v-col cols="4">
                  <v-img
                    v-if="jobOpening.jobContact.profilePicture != null"
                    :src="jobOpening.jobContact.profilePicture"
                    eager
                    class="contact-card__img"
                  ></v-img>
                  <v-img
                    v-else
                    :src="`https://api.dicebear.com/6.x/initials/svg?seed=${jobOpening.jobContact.initials}`"
                    eager
                    class="contact-card__img"
                  ></v-img>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroSectionVue from '@/components/partials/HeroSection.vue';
import jobOpeningService from '@/services/API/jobOpeningService';
export default {
  name: 'JobOpening',
  components: {
    'hero-section': HeroSectionVue,
  },
  data: () => ({
    title: 'Loading...',
    showWhiteWave: true,
    jobOpening: {
      id: null,
      name: null,
      description: null,
      requirements: null,
      responsibilities: null,
      benefits: null,
      location: null,
      category: null,
      softSkills: null,
      hardSkills: null,
      jobContact: null,
      jobType: null,
      footer: null,
    },
    UIController: {
      loader: {
        contentLoading: true,
      },
    },
  }),
  methods: {
    splitter(text) {
      return text.split(/(?<!\\)\n/);
    },
  },
  async created() {
    document.getElementById('loaderSection').classList.add('d-none');
    this.UIController.loader.contentLoading = true;
    await jobOpeningService
      .showJobOpenings(this.$route.params.id)
      .then((response) => {
        if (response.status === 200) {
          this.jobOpening.id = response.data.id;
          this.jobOpening.name = response.data.name;
          this.jobOpening.description = response.data.description;

          const requirementCF = response.data.custom_fields.filter(
            (field) => field.name === 'Requirements'
          );

          this.jobOpening.requirements =
            requirementCF[0].value != undefined
              ? this.splitter(requirementCF[0].value.trim())
              : null;

          const responsibilityCF = response.data.custom_fields.filter(
            (field) => field.name === 'Responsibilities'
          );

          this.jobOpening.responsibilities =
            responsibilityCF[0].value != undefined
              ? this.splitter(responsibilityCF[0].value.trim())
              : null;

          const benefitsCF = response.data.custom_fields.filter(
            (field) => field.name === 'Benefits'
          );

          this.jobOpening.benefits =
            benefitsCF[0].value != undefined
              ? this.splitter(benefitsCF[0].value.trim())
              : null;

          const categoryCF = response.data.custom_fields.filter(
            (field) => field.name === 'Category'
          );

          this.jobOpening.category =
            categoryCF[0].value != undefined
              ? categoryCF[0].type_config.options[categoryCF[0].value]
              : null;

          const hardSkillsCF = response.data.custom_fields.filter(
            (field) => field.name === 'Hard Skills'
          );
          this.jobOpening.hardSkills = hardSkillsCF[0].value
            ? hardSkillsCF[0].type_config.options.filter((field) =>
                hardSkillsCF[0].value.includes(field.id)
              )
            : null;

          try {
            this.jobOpening.jobContact = response.data.custom_fields.filter(
              (field) => field.name === 'Job Contact'
            )[0].value[0];
          } catch (e) {
            this.jobOpening.jobContact = {
              username: 'Priya Dangol',
              email: 'priya.dangol@digu.one',
              initials: 'PD',
              profilePicture:
                'https://attachments.clickup.com/profilePictures/84887098_RtU.jpg',
            };
          }

          const jobTypeCF = response.data.custom_fields.filter(
            (field) => field.name === 'Job Type'
          );

          this.jobOpening.jobType =
            jobTypeCF[0].value != undefined
              ? jobTypeCF[0].type_config.options[jobTypeCF[0].value]
              : null;

          const footerCF = response.data.custom_fields.filter(
            (field) => field.name === 'Footer'
          );

          this.jobOpening.footer =
            footerCF[0].value != undefined
              ? this.splitter(footerCF[0].value.trim())
              : null;
        }
        this.title = this.jobOpening.name;
        this.UIController.loader.contentLoading = false;
      });
  },
  computed: {
    hasJobContact() {
      return (
        this.jobOpening.jobContact.username !== null ||
        this.jobOpening.jobContact.username
      );
    },
  },
};
</script>

<style scoped>
.error-card {
  background-color: var(--light-1);
  max-width: 500px;
  margin-inline: auto;
  margin-block: 2rem;
}

.loader {
  padding-block: 5rem;
}

.job-post {
  margin-block: 2rem;
  border-bottom: 1px solid var(--gray-2);
  border-radius: 0px;
}

.job-post:last-child {
  border: none;
}

.job-post__title {
  font-size: 2rem;
  margin-block: 0.5rem;
  color: var(--dark-2);
}

.job-post__link {
  text-decoration: none;
  color: inherit;
}

.link--footer:hover {
  text-decoration: underline;
}

.job-post__tags {
  margin-block: 0.5rem;
}

.job-post__skill-chip {
  margin-right: 0.5rem;
}

.job-post__description {
  display: block;
  font-size: 0.9rem;
  line-height: 1.5rem;
  white-space: pre-line;
}

.job-post__sub-title {
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
}

.job-post__list {
  margin-bottom: initial;
  margin-left: 1rem;
}

.job-post__list:last-of-type {
  margin-bottom: 1.5rem;
}

.list__item {
  font-size: 0.9rem;
  line-height: 1.5rem;
}

.job-opening__contact-card {
  margin-block: 1rem;
  padding: 1rem;
  border-radius: 0.8rem;
  background-color: var(--light-1);
  max-width: 500px;
}

.contact-card__link {
  text-decoration: none;
  color: inherit;
}

.contact-card__img {
  min-width: 70px;
  max-width: 100px;
  margin-inline: auto;
  border-radius: 75px;
}

.job-opening__apply-button {
  color: var(--light-1);
  letter-spacing: 0px;
  text-transform: capitalize;
}

.contact-card__link {
  text-decoration: underline;
}

.contact__button {
  color: #fff;
  letter-spacing: 0px;
  text-transform: capitalize;
}
</style>
