<template>
  <div class="page--contact" id="view">
    <hero-section />
    <form-section />
  </div>
</template>

<script>
// Local Components
import HeroSectionVue from "@/views/contact/Sections/HeroSection.vue";
import FormSection from "@/views/contact/Sections/FormSection.vue";
export default {
  name: "ContactPage",
  components: {
    "hero-section": HeroSectionVue,
    "form-section": FormSection,
  },
  created() {
    document.getElementById("loaderSection").classList.add("d-none");
  },
};
</script>

<style>
</style>
