<template>
  <v-sheet color="#e3f5ff" height="100%">
    <div class="d-flex flex-column fill-height justify-center align-center">
      <v-row class="section__row" height="100%">
        <v-col
          class="section__col d-flex flex-column fill-height justify-center align-start"
          cols="12"
          sm="12"
          md="12"
          lg="6"
        >
          <h3 class="section__title-2 dms700 mb-4">
            Designed for Productivity and Comfort
          </h3>
          <p class="section__description-2 text-left m400">
            At DIGU Central, we have spared no effort in ensuring that our
            employees have access to a secure and conducive workspace that
            promotes productivity and well-being. The office boasts modern
            architecture and design, incorporating abundant natural light and
            ergonomic furniture to create a comfortable and inviting atmosphere.
          </p>
        </v-col>
        <v-col
          class="section__col d-flex flex-column"
          cols="12"
          sm="12"
          md="12"
          lg="6"
        >
          <keep-alive>
            <v-img
              :src="
                require('@/assets/img/smartworks-central-carousel-slide-two.webp')
              "
              cover
              :eager="true"
              class="carousel__image ma-2"
              :height="400"
            >
            </v-img>
          </keep-alive>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'SlideTwo',
};
</script>

<style scoped>
.section__row {
  padding-inline: 6rem !important;
}

.section__subtitle {
  font-size: 2rem;
}
.section__title-2 {
  font-size: 2rem;
  text-align: left;
  line-height: 120%;
}

.carousel__image {
  border-radius: 2rem !important;
}

.carousel__caption {
  width: 100%;
  margin-bottom: 3rem;
}
</style>
