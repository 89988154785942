<template>
  <section class="section--vision">
    <div class="section__container">
      <v-row class="section__row">
        <v-col class="section__col text-container" cols="12" xs="12" sm="12">
          <h2 class="section__title dms700 section__title text-center">
            Understanding DIGU
          </h2>

          <div class="section__description-container m500 mt-7">
            <p class="section__description text-center">
              DIGU stands for 'Driving Innovation and Growth Unlimited' and is
              more than just an acronym; it carries profound significance in
              Nepali (Newari) culture. The concepts of clan, descent, and
              generation symbolize who we are, where we come from, and our
              commitment to supporting Nepal's future. Rooted in rich heritage,
              DIGU reflects the flourishing of talent, much like a lotus
              blooming from humble beginnings to global success. Our name
              embodies our dedication to creating a supportive and empowering
              environment for exceptional local talent.
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutDIGU',
};
</script>

<style scoped>
.section--vision {
  /* background-color: var(--secondary-color-lightest); */
  padding-block: 3rem;
}

.section__row {
  padding-inline: 0px;
}

.section__col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media only screen and (min-width: 1280px) {
  .text-container {
    text-align: left;
  }
}

.section__title {
  text-align: left;
  color: var(--accent-red);
}

@media only screen and (min-width: 1200px) {
  .section__title {
    text-align: left;
    line-height: 120%;
  }
}

/* @media only screen and (min-width: 1280px) {
  .section__description-container {
    width: 80%;
  }
} */

.section__image {
  border-radius: 0.8rem;
  margin-right: auto;
  aspect-ratio: 4/3;
}

.m-section__image {
  border-radius: 1rem;
  margin-block: 2rem;
  max-height: 200px;
}

@media only screen and (min-width: 700px) {
  .m-section__image {
    max-height: 300px;
  }
}

.section__description {
  margin-top: 0.5rem;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .section__description {
    text-align: center;
  }
}

@media only screen and (min-width: 960px) {
  .m-section__image {
    display: none;
  }
}
</style>
