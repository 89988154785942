<script setup>
import { defineProps, computed } from 'vue';
const props = defineProps({
  message: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  hasImage: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const imageNameFormatter = (name) => {
  const name_ = name.split(' ').join('_').toLowerCase();
  return name_;
};

const developerImage = computed(() => {
  return props.hasImage
    ? require(`@/assets/img/${imageNameFormatter(props.name)}.webp`)
    : `https://api.dicebear.com/8.x/initials/svg?seed=${props.name}`;
});
</script>
<template>
  <div class="feedback-card">
    <div class="feedback-card__header text-left">
      <v-icon class="message-icon">mdi-message</v-icon>
    </div>
    <p class="feedback-card__message">{{ props.message || '' }}</p>
    <div class="feedback-card__details mb-2 mr-2">
      <keep-alive>
        <img :src="developerImage" class="developer-image" height="45"
      /></keep-alive>
      <p class="feedback-card__name text-right">{{ props.name || '' }}</p>
    </div>
  </div>
</template>
<style scoped>
.feedback-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  background-color: var(--light-1);
  width: 100%;
  max-width: 400px;
  height: 400px;
  margin-inline: auto;
  border-radius: 1rem;
}

@media only screen and (min-width: 768px) {
  .feedback-card {
    padding: 1.5rem;
    height: 400px;
  }
}

.message-icon {
  color: var(--secondary-color-lighten-2);
}

.feedback-card__message {
  font-family: 'Lora', serif;
  font-size: 0.9rem;
  color: var(--dark-1);
  font-weight: 500;
}

.feedback-card__details {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.developer-image {
  border-radius: 50px;
  box-shadow: 0px 0px 16px var(--shadow-1);
  margin-right: 1rem;
}
</style>
