import { createRouter, createWebHistory } from 'vue-router';

// Views
import HomeVue from '@/views/home/Home.vue';
import AboutVue from '@/views/about/AboutView.vue';
import ContactPage from '@/views/contact/ContactPage.vue';
import ForDevelopers from '@/views/whySmartworks/forDevelopers/ForDevelopers.vue';
import ForHiringCompanies from '@/views/whySmartworks/forHiringCompanies/ForHiringCompanies.vue';
import SmartworksCentral from '@/views/jobSeekers/smartworksCentral/SmartworksCentral.vue';
import CareVue from '@/views/care/CareVue.vue';
// TODO: enable this
// import CertifiedDeveloper from '@/views/jobSeekers/certifiedDevelopers/CertifiedDeveloper.vue';
import JobPlacementProgram from '@/views/jobSeekers/jobPlacementProgram/JobPlacement.vue';
import Career from '@/views/career/Career.vue';
// renamed to Hire a Professional
// needs file name refactoring
import DeveloperStaffingAsAService from '@/views/service/developerStaffingAsAService/DSAAS.vue';
import SoftwareDevelopmentAsAService from '@/views/service/softwareDevelopmentAsAService/SDAAS.vue';
import JobOpenings from '@/views/jobOpenings/JobOpenings.vue';
import JobOpening from '@/views/jobOpenings/JobOpeningsShow.vue';
import JobApplication from '@/views/jobApplication/JobApplication.vue';
import ListEvents from '@/views/events/ListEvents.vue';
import ShowEvent from '@/views/events/ShowEvent.vue';
import ListTrainings from '@/views/trainings-and-certifications/ListTrainings.vue';
import ShowTrainings from '@/views/trainings-and-certifications/ShowTrainings.vue';
import PrivacyPolicy from '@/views/privacy-policy/PrivacyPolicy.vue';
import NotFound404 from '@/views/404/NotFound404.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeVue,
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutVue,
    meta: {
      title: 'About Us',
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage,
    meta: {
      title: 'Contact Us',
    },
  },
  {
    path: '/for-job-seekers',
    redirect: '/why-digu-for-career-opportunities',
  },
  {
    path: '/why-smartworks-for-career-opportunities',
    redirect: '/why-digu-for-career-opportunities',
  },
  {
    path: '/why-digu-for-career-opportunities',
    name: 'WhyDIGUForCareerOpportunities',
    component: ForDevelopers,
    meta: {
      title: 'Why DIGU For Career Opportunities',
    },
  },
  {
    path: '/for-hiring-companies',
    redirect: '/why-smartworks-for-businesses',
  },
  {
    path: '/why-smartworks-for-businesses',
    redirect: '/why-digu-for-businesses',
  },
  {
    path: '/why-digu-for-businesses',
    name: 'whyDiguForBusinesses',
    component: ForHiringCompanies,
    meta: {
      title: 'Why Digu For Businesses',
    },
  },
  {
    path: '/learn-about-smartworks-central',
    redirect: '/smartworks-central',
  },
  {
    path: '/learn-about-digu-central',
    redirect: '/smartworks-central',
  },
  {
    path: '/smartworks-central',
    redirect: '/digu-central',
  },
  {
    path: '/digu-central',
    name: 'SmartworksCentral',
    component: SmartworksCentral,
    meta: {
      title: 'Learn About DIGU Central Office',
    },
  },
  {
    path: '/care',
    redirect: '/smartworks-cares',
  },
  {
    path: '/smartworks-cares',
    redirect: '/digu-cares',
  },
  {
    path: '/digu-cares',
    name: 'SmartworksCares',
    component: CareVue,
    meta: {
      title: 'DIGU Care',
    },
  },
  // TODO: enable this after page is fixed
  // {
  //   path: '/verified-badges',
  //   name: 'certifiedDeveloper',
  //   component: CertifiedDeveloper,
  //   meta: {
  //     title: 'Become a DIGU Certified Developer',
  //   },
  // },
  {
    path: '/job-placement-program',
    name: 'jobPlacement',
    component: JobPlacementProgram,
    meta: {
      title: 'Job Placement Program',
    },
  },
  {
    path: '/career',
    redirect: '/join-our-talent-pool',
  },
  {
    path: '/join-our-talent-pool',
    name: 'joinOurTalentPool',
    component: Career,
    meta: {
      title: 'Join Our talent Pool',
    },
  },
  {
    path: '/job-openings',
    redirect: '/talents-on-demand',
  },
  {
    path: '/talents-on-demand',
    name: 'developersOnDemand',
    component: JobOpenings,
    meta: {
      title: 'Developers On Demand',
    },
  },
  {
    path: '/job-openings/:id',
    redirect: '/talents-on-demand/:id',
  },
  {
    path: '/talents-on-demand/:id',
    name: 'developersOnDemandShow',
    component: JobOpening,
    meta: {
      title: 'Developers On Demand',
    },
  },
  {
    path: '/job-application',
    name: 'jobApplication',
    component: JobApplication,
    meta: {
      title: 'Job Application',
    },
  },
  {
    path: '/developer-staffing-as-a-service',
    name: 'developerStaffingAsAService',
    redirect: '/hire-a-professional',
  },
  {
    path: '/hire-a-professional',
    name: 'hireAProfessional',
    component: DeveloperStaffingAsAService,
    meta: {
      title: 'Hire a Professional',
    },
  },
  {
    path: '/software-development-as-a-service',
    redirect: '/outsource-your-project',
  },
  {
    path: '/outsource-your-project',
    name: 'outsourceYoutProject',
    component: SoftwareDevelopmentAsAService,
    meta: {
      title: 'Outsource Your Project',
    },
  },
  {
    path: '/events',
    name: 'listEvents',
    component: ListEvents,
    meta: {
      title: 'Upcoming Events',
    },
  },
  {
    path: '/events/:id',
    name: 'showEvent',
    component: ShowEvent,
    meta: {
      title: 'Show Event',
    },
  },
  {
    path: '/trainings-and-certifications',
    name: 'listTrainings',
    component: ListTrainings,
    meta: {
      title: 'Upcoming Trainings and Certifications',
    },
  },
  {
    path: '/trainings-and-certifications/:id',
    name: 'showTrainings',
    component: ShowTrainings,
    meta: {
      title: 'Show Training and Certification',
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy',
    },
  },

  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound404,
    meta: {
      title: 'Not Found',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const title = to.meta.title + ' | DIGU';
  if (title) {
    document.title = title;
  }
  next();
});

export default router;
