<template>
  <div class="neu-footer-section">
    <div class="section__container">
      <p class="neu-footer__title">
        Get in touch with us!
      </p>
      <v-btn elevation="0" class="neu-footer__btn" size="x-large" href="/contact">Connect With Us</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "NeuFooterSektion"
}
</script>

<style scoped>
.neu-footer-section {
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: var(--light-1);
  height: 15rem;
}

.neu-footer__title {
  font-family: 'DM Sans Bold', sans-serif;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.neu-footer__btn {
  background-color: var(--primary-color-darken);
  color: var(--light-1);
  font-family: 'Manrope Medium', sans-serif;
  text-transform: capitalize;
  letter-spacing: 0px;
}
</style>
