<script setup>
import DeveloperCard from '@/components/partials/DeveloperCard.vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import { ref } from 'vue';

const developers = ref([
  {
    name: 'Ujjwal Raja Maharjan',
    title: 'Senior Full Stack Engineer',
    hasImage: true,
    message: `The overall experience from securing my job and onboarding has been exceptionally positive.
DIGU's meticulous preparation and seamless processes not only met but surpassed my
initial expectations. I am fully satisfied and enthusiastic about embarking on this new journey
with the company, confident that it sets the tone for a successful collaboration.`,
  },
  //   {
  //     name: 'Udbodh Shrestha',
  //     title: 'Senior Full Stack Engineer',
  //     hasImage: true,
  //     message: `I am pleased to share that my job securing and onboarding process has been exceptional. The
  // company's innovative and intelligent approach to work aligns seamlessly with my professional
  // goals, and I am excited to contribute to the team's success. I highly appreciate SmartWorks'
  // dedication to its employees, making my onboarding experience positive and setting the stage for
  // a rewarding journey ahead.`,
  //   },
  {
    name: 'Rubin Maharjan',
    title: 'Senior UI/UX Designer',
    hasImage: true,
    message: `I'm truly thankful to DIGU for helping me land full-time employment with a US-based company. Their recruitment process, which was both meticulous and smooth, opened the door for me to work with a diverse and talented global pool. I am grateful to the DIGU team for their unwavering commitment to advancing careers and their efforts in connecting professionals with opportunities worldwide. My career has been significantly transformed through their impact. As I move forward in my professional journey with the skills and confidence they've helped me develop, I look forward to the exciting opportunities ahead.`,
  },
]);

const carouselModel = ref(0);
</script>
<template>
  <h3 class="dms700 title">
    What Do Our <span class="highlight">Talents</span> Say?
  </h3>
  <section class="developer-stories">
    <v-row justify="center" align="center" class="desktop-stories">
      <v-col
        v-for="(developer, index) in developers"
        :key="index"
        cols="12"
        xs="12"
        md="4"
        class="d-flex justify-center"
      >
        <DeveloperCard
          :name="developer.name"
          :title="developer.title"
          :message="developer.message"
          :hasImage="developer.hasImage"
        ></DeveloperCard>
      </v-col>
    </v-row>
  </section>
  <Carousel
    class="mobile-stories"
    v-model="carouselModel"
    :wrap-around="true"
    :items-to-show="1"
  >
    <Slide
      v-for="(developer, index) in developers"
      :key="index"
      class="mobile-stories__slide"
    >
      <div class="pa-3">
        <DeveloperCard
          :name="developer.name"
          :title="developer.title"
          :message="developer.message"
          :hasImage="developer.hasImage"
        ></DeveloperCard>
      </div>
    </Slide>
    <template #addons>
      <Pagination style="background-color: var(--light-1); margin-top: 0px" />
    </template>
  </Carousel>
</template>
<style scoped>
.developer-stories {
  background-color: var(--light-1);
}

.title {
  background-color: var(--light-1);
  padding-block: 2rem;
  line-height: 1.75rem;
  font-size: 1.5rem;
}

.desktop-stories {
  display: none;
  width: 95%;
  max-width: 1400px;
  margin-inline: auto;
}

.mobile-stories__slide {
  background-color: var(--light-1) !important;
}

@media only screen and (min-width: 1280px) {
  .desktop-stories {
    display: flex;
  }

  .mobile-stories {
    display: none;
  }
}

.highlight {
  color: var(--primary-color);
}
</style>
