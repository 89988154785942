<template>
  <div class="job-placement" id="view">
    <hero-section
      subTitle="Job Placement Program"
      :title="title"
      imageName="Job-placement_neu.webp"
      :description="description"
      :showDarkerWave="true"
    />
    <main-container />
    <disclaimer-section />
  </div>
</template>

<script>
import HeroSection from '@/components/partials/HeroSection.vue';
import NewMainContainer from './Sections/NewMainContainer.vue';
import DisclaimerSection from './Sections/DisclaimerSection.vue';

export default {
  components: {
    'hero-section': HeroSection,
    'main-container': NewMainContainer,
    'disclaimer-section': DisclaimerSection,
  },
  data: () => ({
    title: `DIGU Connects Nepali Professionals<br /><span style="color: var(--accent-red)"> With Global Companies.</span> `,
    description: `We are building a bridge between job providers and job seekers. To be part of our program, candidates must be accepted into our Talent Pool. Our Talent Pool features skilled professionals from Nepal, whose resumes we share with clients in the U.S., Canada, and Australia. If your profile matches their needs, we’ll reach out to you.`,
    UIController: {
      display: {
        hideStaffSteps: false,
        hideDeveloperSteps: true,
      },
    },
  }),
  created() {
    document.getElementById('loaderSection').classList.add('d-none');
  },
};
</script>
