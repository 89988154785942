<template>
  <div class="m-carousel-card">
    <v-row>
      <v-col class="d-none d-md-block" cols="12" xs="12" sm="12" md="6">
        <keep-alive>
          <v-img
            :src="
              require('@/assets/img/smartworks-central-carousel-slide-two.webp')
            "
            cover
            :eager="true"
            :height="300"
            class="m-carousel__image ma-2"
          />
        </keep-alive>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6">
        <keep-alive>
          <v-img
            :src="
              require('@/assets/img/smartworks-central-carousel-slide-two.webp')
            "
            cover
            :eager="true"
            :height="300"
            class="m-carousel__image mb-3 d-block d-md-none"
          />
        </keep-alive>
        <h3 class="m-carousel__title text-left dms600 mt-0">
          Designed for Productivity and Comfort
        </h3>
        <p class="m-carousel__description text-left m400 mt-3">
          At DIGU Central, we have spared no effort in ensuring that our
          employees have access to a secure and conducive workspace that
          promotes productivity and well-being. The office boasts modern
          architecture and design, incorporating abundant natural light and
          ergonomic furniture to create a comfortable and inviting atmosphere.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'MobileSlideTwo',
};
</script>
