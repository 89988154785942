<template>
  <section class="section--contact">
    <div class="section__container">
      <v-row class="section__row" justify="center">
        <v-col class="section__col text-center" cols="12">
          <h3 class="dms700 section__contact-title section__title-2 d-flex flex-column">
            <span class="mb-2">{{ titleSans }}</span>
            <span class="dms700">{{ titleSerif }}</span>
          </h3>
        </v-col>
        <v-col class="section__col" cols="12">
          <div class="section__info">
            <div class="contact__links">
              <v-btn elevation="0" class="solid-button o400" :class="{ 'space-top': oversizedOB }" size="x-large"
                :href="solidButtonUrl" v-if="hasSolidButton">{{ solidButtonText }}</v-btn>
              <v-btn elevation="0" variant="outlined" class="outlined-button o400" size="x-large"
                :class="{ 'space-top': oversizedOB }" :href="outlinedButtonUrl" v-if="hasOutlinedButton">{{
                  outlineButtonText }}</v-btn>
            </div>
            <div class="m-contact__links">
              <v-btn elevation="0" class="solid-button o400" :class="{ 'space-top': oversizedOB }" size="large"
                :href="solidButtonUrl" v-if="hasSolidButton">{{ solidButtonText }}</v-btn>
              <v-btn elevation="0" variant="outlined" class="outlined-button o400" size="large"
                :class="{ 'space-top': oversizedOB }" :href="outlinedButtonUrl" v-if="hasOutlinedButton">{{
                  outlineButtonText }}</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactSection",
  props: {
    titleSans: {
      data: String,
      required: true,
    },
    titleSerif: {
      data: String,
      required: true,
    },
    titleDescription: {
      data: String,
      required: false,
    },
    subTitle: {
      data: String,
      required: false,
    },
    subDescription: {
      data: String,
      required: false,
    },
    hasSolidButton: {
      data: Boolean,
      default: false,
    },
    solidButtonText: {
      data: String,
      required: false,
    },
    solidButtonUrl: {
      data: String,
      required: true,
    },
    hasOutlinedButton: {
      data: Boolean,
      default: false,
    },
    outlineButtonText: {
      data: String,
      required: false,
    },
    outlinedButtonUrl: {
      data: String,
      required: false,
    },
    oversizedOB: {
      data: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.section--contact {
  padding-block: 3rem;
  background-color: var(--light-1);
}

@media only screen and (min-width: 1280px) {
  .section--contact {
    padding-inline: none;
  }
}

.section__row {
  padding-inline: 0px;
}

.section__col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section__contact-title {
  text-align: center;
  line-height: 100%;
  width: 100%;
}

@media only screen and (min-width: 700px) {
  .section__contact-title {
    font-size: 2rem;
  }
}

.section__contact-title .dms700 {
  color: var(--accent-red);
}


.section__contact-subtitle {
  text-align: left;
  line-height: 110%;
}

.section__contact-description {
  line-height: 150%;
  margin-bottom: 1rem;
  text-align: left;
}

.section__contact-button {
  background-color: var(--primary-color-darken);
  font-size: 1rem;
  color: var(--light-1);
  font-weight: bold;
  text-transform: capitalize;
}

.contact__links,
.m-contact__links {
  display: none;
  text-align: left;
  width: 100%;
}

@media only screen and (min-width: 1280px) {
  .contact__links {
    text-align: left;
  }
}

.solid-button,
.outlined-button {
  text-transform: capitalize;
  letter-spacing: 0px;
}

.outlined-button {
  border-width: 3px;
}

.solid-button {
  background-color: var(--primary-color-darken);
  color: var(--light-1);
  margin-right: 1rem;
}

.m-contact__links {
  display: block;
}

@media only screen and (min-width: 700px) {
  .contact__links {
    display: block;
  }

  .m-contact__links {
    display: none;
  }
}

.space-top {
  margin-top: 1rem;
}

@media only screen and (min-width: 1200px) {
  .space-top {
    margin-top: initial;
  }
}
</style>
