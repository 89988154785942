import axios from 'axios';
axios.defaults.timeout = 10000;

/**
 * Fetch a list of events
 */
const listAllTrainings = async () => {
  const apiUrl = process.env.VUE_APP_API_URL;
  const trainings = await axios
    .get(`${apiUrl}/v1/public/trainings`)
    .then((response) => {
      return {
        status: response.status,
        data: response.data.data,
      };
    });

  return trainings;
};

const showTraining = async (id) => {
  const apiUrl = process.env.VUE_APP_API_URL;
  const training = await axios
    .get(`${apiUrl}/v1/public/trainings/${id}`)
    .then((response) => {
      return {
        status: response.status,
        data: response.data.data,
      };
    });

  return training;
};

export default {
  listAllTrainings,
  showTraining,
};
