<template>
  <div class="event">
    <HeroSection
      :title="heroDetails.title"
      :showWhiteWave="heroDetails.showWhiteWave"
    ></HeroSection>
    <div class="section__container py-4">
      <div class="loader" v-if="UIController.loader.contentLoading">
        <v-progress-circular indeterminate=""> </v-progress-circular>
        <p class="mt-5">
          Hold On! while we fetch the Trainings and Certifications for you.
        </p>
      </div>
      <div v-else>
        <v-card elevation="0" class="error-card" v-if="!hasDetails">
          <v-card-title class="error-card__title text-left dms700"
            >This Training or Certification Does Not Exist</v-card-title
          >
          <v-card-text>
            <h3 class="error-card__text-title text-left m500">
              Looks like there are no openings available at the moment
            </h3>
            <p class="error-card__text text-left m500">
              Why don't you check back in a day or two to see what we have got
              for you.
            </p>
          </v-card-text>
        </v-card>
        <div v-else>
          <div class="event-detail">
            <div class="event-detail__header">
              <p class="event-detail__sub-title dms600 text-left">
                {{ eventDetail.event_type.name }}
              </p>
              <p class="event-detail__title dms500 text-left">
                <span class="event-detail__title-full">{{
                  eventDetail.name
                }}</span>
              </p>
            </div>
            <div class="event-detail__body">
              <v-row>
                <v-col cols="12" md="8">
                  <v-divider class="mt-3" />
                  <div class="event-detail__meta mt-2">
                    <p class="text-left d-flex align-center mb-2">
                      <v-icon class="event-detail__icon me-5" size="x-small"
                        >mdi-clock</v-icon
                      >
                      <span class="event-detail__date">{{
                        eventDateComputed(eventDetail.event_date_original)
                      }}</span>
                    </p>
                    <p
                      class="text-left d-flex align-center mb-2"
                      v-if="hasMode"
                    >
                      <v-icon class="event-detail__icon me-5" size="x-small"
                        >mdi-map-marker</v-icon
                      >
                      <span class="event-detail__date">{{
                        dynamicAddress
                      }}</span>
                    </p>
                    <p class="text-left d-flex align-center mb-2">
                      <v-icon class="event-detail__icon me-5" size="x-small"
                        >mdi-microphone</v-icon
                      >
                      <span
                        class="event-detail__date"
                        :innerText="trimmedSpeakerValue"
                      ></span>
                    </p>
                    <p class="text-left d-flex align-center mb-2">
                      <v-icon class="event-detail__icon me-5" size="x-small"
                        >mdi-asterisk</v-icon
                      >
                      <span class="event-detail__date"
                        >Entry Type: {{ eventDetail.entry_type.name }}</span
                      >
                    </p>
                    <p
                      class="text-left d-flex align-center mb-2"
                      v-if="isTrainingPaid"
                    >
                      <v-icon class="event-detail__icon me-5" size="x-small"
                        >mdi-cash</v-icon
                      >
                      <span class="event-detail__date">{{
                        eventDetail.entry_fees
                      }}</span>
                    </p>
                  </div>
                  <div
                    class="event-detail__register-container text-left mt-4 d-flex align-start flex-column"
                  >
                    <add-to-calendar-button
                      :name="eventDetail.name"
                      :description="eventDetail.description"
                      :startDate="
                        getTrainingDate(eventDetail.event_date_original)
                      "
                      :startTime="getStartTime(eventDetail.event_date_original)"
                      :endTime="getEndTime(eventDetail.event_date_original)"
                      :timeZone="getTimeZone()"
                      options="'Apple','Google'"
                      trigger="click"
                      hideCheckmark
                      hideIconButton
                      size="0"
                      class="mb-3 custom-btn"
                    ></add-to-calendar-button>
                    <ShareButton :title="eventDetail.name"></ShareButton>
                  </div>
                  <div class="d-md-none mt-6">
                    <img
                      class="event-detail__image"
                      :src="eventDetail.event_banner"
                    />
                  </div>
                  <div class="event-detail__description-container mt-4">
                    <p
                      class="event-detail__description text-left"
                      v-for="(p, index) in eventDetail.description"
                      :key="index"
                    >
                      {{ p }}
                    </p>
                  </div>
                  <div class="job-opening__contact-card">
                    <v-row class="contact-card">
                      <v-col cols="8">
                        <p
                          class="job-opening__contact-title text-left m600 mb-2"
                        >
                          Contact
                        </p>
                        <p class="contact-card__name text-left">
                          <span class="contact-card__text">{{
                            'Priya Dangol'
                          }}</span>
                        </p>
                        <p
                          class="contact-card__mail text-left d-none d-md-block"
                        >
                          <a
                            :href="`mailto:${'priya.dangol@digu.one'}`"
                            class="contact-card__link"
                            >{{ 'priya.dangol@digu.one' }}</a
                          >
                        </p>
                        <p class="contact-card__mail text-left d-md-none">
                          <v-btn
                            class="mt-3 contact__button m700"
                            color="#004871"
                            :href="`mailto:${'priya.dangol@digu.one'}`"
                            elevation="0"
                            >Send Email</v-btn
                          >
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <v-img
                          src="https://attachments.clickup.com/profilePictures/84887098_RtU.jpg"
                          eager
                          class="contact-card__img"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </div>
                  <div
                    class="event-detail__register-container text-left mt-4"
                    v-if="hasRegistrationLink"
                  >
                    <v-btn
                      elevation="0"
                      class="event-detail__register-btn o400 mb-5"
                      append-icon="mdi-arrow-top-right"
                      :href="eventDetail.registration_link"
                      >Register for Training</v-btn
                    >
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="d-none d-md-flex">
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <img
                        class="event-detail__image"
                        :src="eventDetail.event_banner"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-snackbar
      v-model="UIController.snackbar.active"
      :location="UIController.snackbar.location"
      :text="UIController.snackbar.text"
      :color="UIController.snackbar.color"
    >
      {{ UIController.snackbar.text }}</v-snackbar
    >
  </div>
</template>
<script setup>
import HeroSection from '@/components/partials/HeroSection.vue';
import ShareButton from '@/components/partials/ShareButton.vue';

import { computed, onMounted, reactive, ref } from 'vue';

import trainingsService from '@/services/API/trainingsService';
import { useRoute } from 'vue-router';
import 'add-to-calendar-button';
// Global Variables
const heroDetails = ref({
  title: 'Training and Certification Details',
  showWhiteWave: true,
});

const UIController = reactive({
  loader: {
    contentLoading: true,
  },
  snackbar: {
    active: false,
    location: 'bottom right',
    text: '',
    color: 'danger',
  },
});

let eventDetail = reactive({});

const splitter = (text) => {
  console.log(text);
  return text.split(/(?<!\\)\n/);
};

const eventDateComputed = (date) => {
  return new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'full',
    timeStyle: 'short',
    hour12: true,
  }).format(new Date(parseInt(date)));
};

const getTrainingDate = (date) => {
  return new Intl.DateTimeFormat('fr-CA', {
    year: 'numeric',
    day: '2-digit',
    month: '2-digit',
    timeZone: 'UTC',
    localeMatcher: 'best fit',
  }).format(new Date(parseInt(date)));
};

const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
const getStartTime = (date) => {
  const date_ = new Date(parseInt(date));
  return `${
    date_.getHours() < 10 ? '0' + date_.getHours() : date_.getHours()
  }:${date_.getMinutes() < 10 ? '0' + date_.getMinutes() : date_.getMinutes()}`;
};
const getEndTime = (date) => {
  const date_ = new Date(parseInt(date) + 2 * 60 * 60 * 1000);
  return `${
    date_.getHours() < 10 ? '0' + date_.getHours() : date_.getHours()
  }:${date_.getMinutes() < 10 ? '0' + date_.getMinutes() : date_.getMinutes()}`;
};

//  Initial Setup
onMounted(async () => {
  document.getElementById('loaderSection').classList.add('d-none');
  await trainingsService
    .showTraining(useRoute().params.id)
    .then((response) => {
      UIController.snackbar.text =
        'Trainings and Certifications fetched successfully';
      UIController.snackbar.color = 'success';
      UIController.snackbar.active = true;
      eventDetail = response.data;
      eventDetail.description = splitter(response.data.description);
      console.log(eventDetail.description);
      UIController.loader.contentLoading = false;
    })
    .catch(() => {
      UIController.snackbar.text = 'Could not fetch events and Certifications';
      UIController.snackbar.color = 'error';
      UIController.snackbar.active = true;
    });
});

// Computed Properties
const hasDetails = computed(() => {
  return Object.keys(eventDetail).length > 0 ? true : false;
});

const hasRegistrationLink = computed(() => {
  // The response needs to have a registration link attribute
  return (
    Object.keys(eventDetail).includes('registration_link') &&
    (eventDetail.registration_link !== '' ||
      eventDetail.registration_link !== null ||
      eventDetail.registration_link !== undefined)
  );
});

const dynamicAddress = computed(() => {
  return eventDetail.event_mode.name === 'In Person' &&
    Object.keys(eventDetail).includes('location')
    ? eventDetail.location
    : eventDetail.event_mode.name;
});

const hasMode = computed(() => {
  return 'name' in eventDetail.event_mode;
});

const trimmedSpeakerValue = computed(() => {
  return eventDetail.instructor.trim();
});

const isTrainingPaid = computed(() => {
  return (
    Object.keys(eventDetail).includes('entry_fees') &&
    eventDetail.entry_type.name === 'Paid' &&
    (eventDetail.entry_fees !== '' ||
      eventDetail.entry_fees !== null ||
      eventDetail.entry_fees !== undefined)
  );
});
</script>
<style scoped>
.error-card {
  background-color: var(--light-1);
  max-width: 500px;
  margin-inline: auto;
  margin-block: 2rem;
}

.loader {
  padding-block: 5rem;
}

.event-card {
  margin-block: 2rem;
  border-bottom: 1px solid var(--gray-2);
  border-radius: 0px;
}

.event-detail__info-card {
  max-width: 1400px;
}

.event-card:last-child {
  border: none;
}

.event-detail__header {
  margin-bottom: 1rem;
}

.event-detail__sub-title {
  font-size: 0.8rem;
  margin-bottom: 0px;
}

@media only screen and (min-width: 768px) {
  .event-detail__sub-title {
    font-size: 1rem;
  }
}

.event-detail__title {
  font-size: 1.25rem;
  line-height: 1.2;
  margin-block: 0.3rem;
  color: var(--primary-color-lighten);
}

.event-detail__title-seprator {
  margin-inline: 0.25rem;
}

@media only screen and (min-width: 768px) {
  .event-detail__title {
    font-size: 2rem;
  }
}

.event-detail__icon {
  color: var(--primary-color-darken);
}

.event-detail__date {
  font-size: 0.9rem;
}

.event-detail__link {
  text-decoration: none;
  color: inherit;
}
.event-detail__image {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 0.4rem;
  object-fit: cover;
}

.link--footer:hover {
  text-decoration: underline;
}

.event-detail__tags {
  margin-block: 0.5rem;
}

.event-detail__skill-chip {
  margin-right: 0.5rem;
}

.event-detail__description {
  font-size: 0.9rem;
  line-height: 1.5rem;
  margin-bottom: 0.9rem;
}

.event-detail__description:last-of-type {
  margin-bottom: 0px;
}

.event-detail__contact-card {
  margin-block: 1rem;
  padding: 1rem;
  border-radius: 0.8rem;
  background-color: var(--light-1);
  max-width: 500px;
}

.contact-card__link {
  text-decoration: none;
  color: inherit;
}

.contact-card__img {
  min-width: 70px;
  max-width: 100px;
  margin-inline: auto;
  border-radius: 75px;
}

.event-detail__apply-button {
  color: var(--light-1);
  letter-spacing: 0px;
  text-transform: capitalize;
}

.contact-card__link {
  text-decoration: underline;
}

.contact__button {
  color: #fff;
  letter-spacing: 0px;
  text-transform: capitalize;
}

.event-detail__register-btn {
  background-color: var(--primary-color);
  color: var(--white-1);
}

#atcb-btn-1 {
  box-shadow: none !important;
}

.atcb-list-wrapper #atcb-reference {
  display: none;
}

.job-opening__contact-card {
  margin-block: 1rem;
  padding: 1rem;
  border-radius: 0.8rem;
  background-color: var(--light-1);
  max-width: 500px;
}

.contact-card__link {
  text-decoration: none;
  color: inherit;
}

.contact-card__img {
  min-width: 70px;
  max-width: 100px;
  margin-inline: auto;
  border-radius: 75px;
}

.job-opening__apply-button {
  color: var(--light-1);
  letter-spacing: 0px;
  text-transform: capitalize;
}

.contact-card__link {
  text-decoration: underline;
}

.contact__button {
  color: #fff;
  letter-spacing: 0px;
  text-transform: capitalize;
}
</style>
