<template>
  <div class="m-carousel-card">
    <v-row>
      <v-col class="d-none d-md-block" cols="12" xs="12" sm="12" md="6" lg="6">
        <keep-alive>
          <v-img
            :src="
              require('@/assets/img/smartworks-central-carousel-slide-four.webp')
            "
            cover
            :eager="true"
            :height="300"
            class="m-carousel__image ma-2"
          >
          </v-img>
        </keep-alive>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <keep-alive>
          <v-img
            :src="
              require('@/assets/img/smartworks-central-carousel-slide-four.webp')
            "
            cover
            :eager="true"
            :height="300"
            class="m-carousel__image mb-3 d-block d-md-none"
          >
          </v-img>
        </keep-alive>
        <h3 class="m-carousel__title text-left dms600 mt-0">
          Embracing the Future of Business
        </h3>
        <p class="m-carousel__description text-left m400 mt-3">
          With DIGU Central, we are ready to make a significant impact in the
          tech industry, both locally and globally. By establishing a
          world-class office space in Kathmandu, we are positioning ourselves at
          the forefront of innovation and business excellence. Our commitment to
          providing a conducive work environment reflects our dedication to
          empowering our team and driving transformative outcomes for our
          clients.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'MobileSlideOne',
};
</script>
