<template>
  <div class="smartworks-central" id="view">
    <hero-section
      :title="title"
      :subTitle="subTitle"
      :description="description"
      :imageName="imageName"
    />
    <carousel-section></carousel-section>
  </div>
</template>

<script>
import HeroSection from '@/components/partials/HeroSection.vue';
import CarouselSection from './Sections/CarouselSection.vue';
export default {
  components: {
    'hero-section': HeroSection,
    'carousel-section': CarouselSection,
  },
  data: () => ({
    title: 'Unveiling An Innovative Office Space In The Heart Of Kathmandu',
    subTitle: 'DIGU Central',
    description:
      "DIGU Central represents our commitment to fostering a conducive work environment that inspires creativity, collaboration, growth. With meticulous planning and a 4-month renovation process, we have created an exceptional workspace that caters to the needs of our employees, while also embracing the beauty of Kathmandu's natural surroundings.",
    imageName: 'smartworks-central-hero.webp',
  }),
  created() {
    document.getElementById('loaderSection').classList.add('d-none');
  },
};
</script>

<style></style>
