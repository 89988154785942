// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme:{
    themes:{
      light:{
        colors:{
          primary: "#2EA3F2",
          secondary: "#E54100",
          headingGreyDark: "#17171F",
        }
      }
    }
  }
}
)
