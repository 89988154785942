<template>
  <section class="section--goal">
    <div class="section__info">
      <h2 class="section__title dms700">Our Values</h2>
    </div>
    <v-row class="m-section__row">
      <v-col class="section__col text-container" cols="6" sm="6" lg="2" xl="2">
        <v-img
          eager
          cover
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/excellence.svg')"
        ></v-img>
        <p class="value__title m600">Excellence</p>
      </v-col>
      <v-col class="section__col text-container" cols="6" sm="6" lg="2" xl="2">
        <v-img
          eager
          cover
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/ict.svg')"
        ></v-img>
        <p class="value__title m600">Integrity</p>
      </v-col>
      <v-col class="section__col text-container" cols="6" sm="6" lg="2" xl="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/teamwork.svg')"
        ></v-img>
        <p class="value__title m600">Collaboration</p>
      </v-col>
      <v-col class="section__col text-container" cols="6" sm="6" lg="2" xl="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/customer-satisfaction.svg')"
        ></v-img>
        <p class="value__title m600">Customer First</p>
      </v-col>
      <v-col class="section__col text-container" cols="6" sm="6" lg="2" xl="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/respect.svg')"
        ></v-img>
        <p class="value__title m600">Respect</p>
      </v-col>
      <v-col class="section__col text-container" cols="6" sm="6" lg="2" xl="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/accountability.svg')"
        ></v-img>
        <p class="value__title m600">Accountability</p>
      </v-col>
      <v-col class="section__col text-container" cols="6" sm="6" lg="2" xl="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/adaptation.svg')"
        ></v-img>
        <p class="value__title m600">Adaptability</p>
      </v-col>
      <v-col class="section__col text-container" cols="6" sm="6" lg="2" xl="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/job-promotion.svg')"
        ></v-img>
        <p class="value__title m600">Growth and Development</p>
      </v-col>
      <v-col class="section__col text-container" cols="6" sm="6" lg="2" xl="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/public-service.svg')"
        ></v-img>
        <p class="value__title m600">Social Responsibility</p>
      </v-col>
      <v-col class="section__col text-container" cols="6" sm="6" lg="2" xl="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/balance.svg')"
        ></v-img>
        <p class="value__title m600">Work-Life Balance</p>
      </v-col>
    </v-row>
    <v-row class="section__row mb-10" justify="center">
      <v-col class="section__col text-container" cols="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/excellence.svg')"
        ></v-img>
        <p class="value__title m600">Excellence</p>
      </v-col>
      <v-col class="section__col text-container" cols="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/ict.svg')"
        ></v-img>
        <p class="value__title m600">Integrity</p>
      </v-col>
      <v-col class="section__col text-container" cols="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/teamwork.svg')"
        ></v-img>
        <p class="value__title m600">Collaboration</p>
      </v-col>
      <v-col class="section__col text-container" cols="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/customer-satisfaction.svg')"
        ></v-img>
        <p class="value__title m600">Customer First</p>
      </v-col>
      <v-col class="section__col text-container" cols="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/respect.svg')"
        ></v-img>
        <p class="value__title m600">Respect</p>
      </v-col>
    </v-row>
    <v-row class="section__row" justify="center">
      <v-col class="section__col text-container" cols="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/accountability.svg')"
        ></v-img>
        <p class="value__title m600">Accountability</p>
      </v-col>
      <v-col class="section__col text-container" cols="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/adaptation.svg')"
        ></v-img>
        <p class="value__title m600">Adaptability</p>
      </v-col>
      <v-col class="section__col text-container" cols="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/job-promotion.svg')"
        ></v-img>
        <p class="value__title m600">Growth and Development</p>
      </v-col>
      <v-col class="section__col text-container" cols="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/public-service.svg')"
        ></v-img>
        <p class="value__title m600">Social Responsibility</p>
      </v-col>
      <v-col class="section__col text-container" cols="2">
        <v-img
          cover
          eager
          class="goal__img mx-auto mb-3"
          width="75"
          :src="require('@/assets/img/balance.svg')"
        ></v-img>
        <p class="value__title m600">Work-Life Balance</p>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "GoalSection",
};
</script>

<style scoped>
.section--goal {
  padding-inline: 2rem;
  padding-block: 5rem;
  background-color: var(--secondary-color-lightest);
  background-size: contain;
  background-blend-mode: color-burn;
  background-position: center;
}

@media only screen and (min-width: 1280px) {
  .section--goal {
    background-blend-mode: multiply;
  }
}

.section__info {
  text-align: center;
}
.section__row {
  max-width: 1400px;
  display: none;
  margin-inline: auto;
}

@media only screen and (min-width: 700px) {
  .section__row {
    display: flex;
  }
}
.section__subtitle {
  color: var(--accent-red);
  font-size: 1.25rem;
}

.section__title {
  font-size: 2rem;
  margin-bottom: 3rem;
  color: var(--light-1);
  color: var(--accent-red);
}

@media only screen and (min-width: 1280px) {
  .section__title {
    line-height: 120%;
    width: 60%;
    margin-inline: auto;
  }
}

.section__list {
  list-style: none;
}

@media only screen and (min-width: 1280px) {
  .section__list {
    padding-bottom: 5rem;
    width: 80%;
    margin-left: auto;
  }
}

.list__item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.list__text {
  text-align: left;
  margin-left: 1rem;
}

@media only screen and (min-width: 700px) {
  .m-section__row {
    display: none;
  }
}

.value__title {
  font-size: 1rem;
}
</style>
