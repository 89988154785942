<template>
  <div class="m-carousel-card">
    <v-row>
      <v-col class="d-none d-md-block" cols="12" xs="12" sm="12" md="6">
        <keep-alive>
          <v-img
            :src="require('@/assets/img/programs-section.webp')"
            cover
            :eager="true"
            :height="300"
            class="m-carousel__image ma-2"
          >
          </v-img>
        </keep-alive>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6">
        <keep-alive>
          <v-img
            :src="require('@/assets/img/programs-section.webp')"
            cover
            :eager="true"
            :height="300"
            class="m-carousel__image mb-3 d-block d-md-none"
          >
          </v-img>
        </keep-alive>
        <h3 class="m-carousel__title text-left dms600 mt-0">
          A Place to Collaborate, Network, and Unwind
        </h3>
        <p class="m-carousel__description text-left m400 mt-3">
          We understand the importance of fostering collaboration and building
          connections within our organization. DIGU Central features dedicated
          spaces designed specifically for teamwork and interaction. These areas
          encourage employees to engage in lively discussions, brainstorm ideas,
          and develop innovative solutions. Additionally, our recreational area
          provides a space for relaxation and rejuvenation, allowing employees
          to unwind and recharge their creative energies.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'MobileSlideThree',
};
</script>
