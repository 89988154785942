<script setup>
import { reactive, ref } from 'vue';
import DeveloperStories from '@/views/whySmartworks/forDevelopers/Sections/DeveloperStories.vue';
import ClientStories from '@/views/whySmartworks/forHiringCompanies/Sections/ClientStories.vue';

const current = ref('Customer');

const UIController = reactive({
  showingClients: true,
  showingDevelopers: false,
});

const show = (type) => {
  current.value = type;
  UIController.showingClients = type === 'Customer';
  UIController.showingDevelopers = type === 'Developers';
};
</script>
<template>
  <section class="stories">
    <div class="section__header">
      <div class="btn-container mb-2">
        <button
          class="btn btn--right btn--client"
          :class="{ active: UIController.showingClients }"
          @click="show('Customer')"
        >
          Customer Testimonials
        </button>
        <button
          class="btn btn--right btn--developer"
          :class="{ active: UIController.showingDevelopers }"
          @click="show('Developers')"
        >
          Talent Testimonials
        </button>
      </div>
    </div>
    <div class="section__content">
      <ClientStories v-if="UIController.showingClients" />
      <DeveloperStories v-if="UIController.showingDevelopers" />
    </div>
  </section>
</template>
<style scoped>
.stories {
  padding-block: 2rem 4rem;
  background-color: var(--light-1);
  transition: all 200ms ease-in-out;
}

@media only screen and (min-width: 1028px) {
  .stories {
    padding-block: 4rem;
  }
}
.section__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.btn-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
  min-width: 175px;
  padding: 5px;
  background-color: var(--gray-3);
  border-radius: 10px;
}

.btn {
  padding: 5px;
  border-radius: 5px;
  transition: background-color ease-in-out 200ms 10ms;
  background-color: var(--light-1);
  color: var(--gray-1);
}

.btn:hover {
  color: var(--dark-1);
}

.btn--client.active {
  background-color: var(--accent-red);
  color: var(--white-1);
}

.btn--developer.active {
  background-color: var(--primary-color);
  color: var(--white-1);
}

.title {
  font-size: 1.5rem;
}
</style>
