<template>
  <div class="view--404" id="view">
    <p class="error__type o600">404 Not Found</p>
    <h1 class="error__title dms700">
      Looks like the page you are looking for does not exist.
    </h1>
  </div>
</template>

<script>
export default {
  name: "NotFound404",
  created() {
    document.getElementById("loaderSection").classList.add("d-none");
  },
};
</script>

<style scoped>
.view--404 {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--light-1);
}

.error__type {
  color: var(--accent-red);
  font-size: 1.25rem;
}
</style>
