<template>
  <section class="aim-section">
    <div class="section__container">
      <v-row class="aim__row">
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          class="section__col aim__img"
        >
          <keep-alive>
            <v-img
              class="aim__image"
              cover
              :eager="true"
              :lazy-src="require('@/assets/img/lazy-img.jpg')"
              :src="require('@/assets/img/home-why-we-exist.webp')"
            >
              <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height">
                  <v-progress-circular
                    color="grey-lighten-4"
                    indeterminate
                  ></v-progress-circular>
                </div> </template
            ></v-img>
          </keep-alive>
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          class="aim__text section__col"
        >
          <div class="content__wrapper">
            <p class="o600 aim__subtitle section__subtitle-2">Why We Exist</p>
            <keep-alive>
              <v-img
                class="mobile-aim__image"
                :lazy-src="require('@/assets/img/lazy-img.jpg')"
                cover
                :eager="true"
                :src="require('@/assets/img/home_why-we-exist_neu.webp')"
              >
                <template v-slot:placeholder>
                  <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular
                      color="grey-lighten-4"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </template>
              </v-img>
            </keep-alive>
            <p class="m500 aim__description section__description-2">
              Our roots originate in Nepal - a beautiful country with limited
              career opportunities. This lack of opportunity drives Nepali
              professionals to look for tech jobs outside of Nepal.
            </p>
            <p class="m500 aim__description section__description-2">
              On the other side, global companies are facing challenges in
              finding suitable, highly skilled candidates without breaking the
              bank. DIGU strives to solve both by empowering and elevating the
              lives of current and next generation Nepali professionals.
            </p>

            <div class="aim__btn-container">
              <v-btn
                class="aim__button o500"
                elevation="0"
                size="x-large"
                href="/about"
                >Get to Know Us</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AimSection',
  created() {
    this.screen.width = window.screen.width;
    this.screen.height = window.screen.height;
  },
  data: () => ({
    screen: {
      width: null,
      height: null,
    },
  }),
};
</script>

<style scoped>
.aim-section {
  display: block;
  padding-block: 7rem 7rem;
  background: var(--gradient-1);
}

@media only screen and (min-width: 1200px) {
  .aim-section {
    padding-block: 20rem 10rem;
  }
}

.aim__row {
  padding-inline: 0px;
}

.aim__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (min-width: 1280px) {
  .aim__text {
    padding-inline: 2rem 0px;
  }
}

.aim__subtitle,
.aim__title {
  text-align: left;
}

@media only screen and (min-width: 1200px) {
  .aim__subtitle,
  .aim__title {
    text-align: left;
  }
}

.aim__subtitle {
  color: var(--primary-color-darken);
  margin-bottom: 0.75rem;
  font-size: 2rem !important;
}

.aim__title {
  color: var(--dark-3);
  line-height: 110%;
  margin-bottom: 0.5rem;
}

.aim__description {
  text-align: left;
  font-size: 1;
  color: var(--dark-2);
  margin-bottom: 1rem;
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .aim__description {
    text-align: left;
  }
}

.aim__btn-container {
  text-align: left;
  margin-top: 3rem;
}

.aim__button {
  background-color: var(--light-1);
  color: var(--primary-color-lighten);
  text-transform: capitalize;
  letter-spacing: 0px;
  font-size: 1.25rem;
  text-align: left;
}

.aim__img {
  display: none;
}

@media only screen and (min-width: 960px) {
  .aim__img {
    display: block;
  }
}

.aim__image {
  border-radius: 0.8rem;
  margin-right: auto;
  padding-right: 0px;
  aspect-ratio: 5/6;
}

.mobile-aim__image {
  margin-block: 2rem;
  margin-inline: auto;
  border-radius: 0.8rem;
  aspect-ratio: 5/6;
  max-width: 500px;
  margin-inline: auto;
}

@media only screen and (min-width: 960px) {
  .mobile-aim__image {
    display: none;
  }
}
</style>
