<template>
  <div class="service" id="view">
    <hero-section :title="title" :subTitle="subTitle" :description="description" :showDarkerWave="true"
      imageName="outsource-your-projects.png" :hasButton="false" buttonText="Learn more about verified professionals"
      buttonUrl="/verified-badges" />
    <new-main-container />
    <new-footer-section />
  </div>
</template>

<script>
import HeroSectionVue from "@/components/partials/HeroSection.vue";
import NewMainContainerVue from "./Sections/NewMainContainer.vue";
import NeuFooterSektion from "@/views/about/Sections/NeuFooterSektion.vue";
export default {
  name: "SDAAS",
  components: {
    "hero-section": HeroSectionVue,
    "new-main-container": NewMainContainerVue,
    "new-footer-section": NeuFooterSektion,
  },
  data: () => ({
    title: "Outsource your Project",
    subTitle: "Our Services",
    description: "If you're looking to outsource your technical projects without breaking the bank- we have a team of highly skilled developers, project managers, and architects to support your success.<br />Our expertise includes custom software development, Web and application development, software maintenance and support, Big Data Analytics, implementation with third - party applications, and Cloud computing services.",
  }),
  created() {
    document.getElementById("loaderSection").classList.add("d-none");
  },
};
</script>

