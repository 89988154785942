<template>
  <div class="employee-benefits" id="view">
    <hero-section
      :subTitle="subTitle"
      :description="description"
      :showWhiteWave="true"
      :hasButton="hasButton"
      :buttonText="buttonText"
      :buttonUrl="buttonUrl"
    />
    <main-section />
  </div>
</template>

<script>
// Global Components
import HeroSection from '@/components/partials/HeroSection.vue';

// Local Components
import MainSection from '@/views/career/Sections/MainSection.vue';

export default {
  name: 'CareerVue',
  components: {
    'hero-section': HeroSection,
    'main-section': MainSection,
  },
  data: () => ({
    title: 'Career',
    subTitle: 'Join our Talent Pool',
    hasButton: true,
    buttonText: 'Join Now',
    buttonUrl: '/talents-on-demand',
    description:
      'At DIGU, we strive to provide comprehensive benefit packages that support your well-being, professional growth, and work-life balance.',
  }),
  created() {
    document.getElementById('loaderSection').classList.add('d-none');
  },
};
</script>

<style scoped></style>
