<template>
  <section class="section--form">
    <div class="form__info">
      <h3 class="form__title dms700">Questions?</h3>
    </div>
    <div class="form__container">
      <form method="post" class="form" ref="contactForm">
        <v-text-field
          label="Your Name *"
          aria-label="Your Name"
          class="input-field m500 mb-3"
          bg-color="#f7f8fc"
          v-model="contactMessage.name"
          :error="contactError.name"
          :error-messages="contactErrorMessage.name"
          aria-required="true"
          aria
        ></v-text-field>
        <v-text-field
          label="Email *"
          aria-label="Your Email"
          bg-color="#f7f8fc"
          autocomplete="email"
          v-model="contactMessage.email"
          :error="contactError.email"
          :error-messages="contactErrorMessage.email"
          type="email"
          class="input-field m500 mb-3"
          aria-required="true"
        ></v-text-field>
        <v-text-field
          label="Company"
          aria-label="Your Company Name"
          class="input-field m500 mb-3"
          v-model="contactMessage.company"
          :error="contactError.company"
          :error-messages="contactErrorMessage.company"
          bg-color="#f7f8fc"
          aria-required="false"
        ></v-text-field>
        <v-text-field
          label="Phone"
          aria-label="Your Phone Number"
          class="input-field m500 mb-3"
          v-model="contactMessage.phoneNumber"
          :error="contactError.phoneNumber"
          :error-messages="contactErrorMessage.phoneNumber"
          bg-color="#f7f8fc"
        ></v-text-field>
        <v-textarea
          label="Message *"
          aria-label="Your Message"
          rows="4"
          v-model="contactMessage.message"
          :error="contactError.message"
          :error-messages="contactErrorMessage.message"
          class="input-field m500 mb-3"
          auto-grow
          bg-color="#f7f8fc"
          aria-required="true"
          required
        >
        </v-textarea>
        <hcaptcha
          classList="my-4 text-left d-none d-sm-block"
          @verify="onVerify"
          @expired="onExpired"
          @closed="onClosed"
          @reset="onReset"
        />
        <hcaptcha
          classList="my-4 text-left d-block d-sm-none"
          @verify="onVerify"
          @expired="onExpired"
          @closed="onClosed"
          @reset="onReset"
          size="compact"
        />
        <div class="button-container">
          <v-btn
            class="o500 submit-btn"
            aria-label="Send Contact Message"
            elevation="0"
            size="x-large"
            color="#004871"
            @click="validateForm"
            :loading="UIController.loader.btnLoader"
            >Submit</v-btn
          >
        </div>
      </form>
      <v-snackbar
        color="red-darken-2"
        v-model="UIController.snackbars.captchaSnackBar"
        >Please Verify The Captcha</v-snackbar
      >
      <v-snackbar
        color="red-darken-2"
        v-model="UIController.snackbars.errorSnackbar"
        >Could Not Send Message</v-snackbar
      >
      <v-snackbar
        color="green-darken-2"
        v-model="UIController.snackbars.successSnackBar"
        >Message Sent</v-snackbar
      >
    </div>
  </section>
</template>

<script>
import contactService from "@/services/API/contactService";
import hcaptchaWidget from "@/components/partials/hcaptchaWidget.vue";
export default {
  name: "FormContainer",
  components: {
    hcaptcha: hcaptchaWidget,
  },
  methods: {
    onVerify() {
      this.contactMessage.verified = true;
    },
    onExpired() {
      this.contactMessage.verified = false;
    },
    onClosed() {
      this.contactMessage.verified = false;
    },
    onReset() {
      this.contactMessage.verified = false;
    },

    validateForm() {
      if (!this.contactMessage.name) {
        this.contactError.name = true;
        this.contactErrorMessage.name.push("This Field Is Required");
      } else {
        this.contactError.name = false;
        this.contactErrorMessage.name = new Array();
      }

      if (!this.contactMessage.email) {
        this.contactError.email = true;
        this.contactErrorMessage.email.push("This Field Is Required");
      } else {
        this.contactError.email = false;
        this.contactErrorMessage.email = new Array();
      }

      if (!this.contactMessage.message) {
        this.contactError.message = true;
        this.contactErrorMessage.message.push("This Field Is Required");
      } else {
        this.contactError.message = false;
        this.contactErrorMessage.message = new Array();
      }

      if (!this.contactMessage.verified) {
        this.contactError.verified = true;
        this.UIController.snackbars.captchaSnackBar =
          this.contactError.verified;
        this.contactErrorMessage.verified.push("Please Verify This Captcha");
      } else {
        this.contactError.verified = false;
        this.contactErrorMessage.verified = new Array();
      }

      if (
        this.contactError.name ||
        this.contactError.email ||
        this.contactError.message ||
        this.contactError.verified
      ) {
        return false;
      } else {
        this.sendMessage();
      }
    },
    async sendMessage() {
      this.UIController.loader.btnLoader = true;
      const messageParams = {
        name: this.contactMessage.name,
        email: this.contactMessage.email,
        company: this.contactMessage.company,
        phoneNumber: this.contactMessage.phoneNumber,
        message: this.contactMessage.message,
      };

      try {
        await contactService.sendEmail(messageParams).then((response) => {
          console.log(response);
          this.UIController.loader.btnLoader = false;
          this.contactMessage.name = null;
          this.contactMessage.email = null;
          this.contactMessage.company = null;
          this.contactMessage.phoneNumber = null;
          this.contactMessage.message = null;
          this.contactMessage.verified = false;
          // reset the error
          this.contactError.name = false;
          this.contactError.email = false;
          this.contactError.company = false;
          this.contactError.phoneNumber = false;
          this.contactError.message = false;
          this.contactError.verified = false;
          this.UIController.snackbars.successSnackBar = true;
        });
      } catch (error) {
        console.error(error);
        this.UIController.loader.btnLoader = false;
        this.contactMessage.name = null;
        this.contactMessage.email = null;
        this.contactMessage.company = null;
        this.contactMessage.phoneNumber = null;
        this.contactMessage.message = null;
        this.contactMessage.verified = false;
        this.UIController.snackbars.errorSnackBar = true;
      }

      await contactService.sendEmail(messageParams).then((response) => {
        console.log(response);
        this.UIController.loader.btnLoader = false;
      });
    },
  },
  data: () => ({
    contactMessage: {
      name: null,
      email: null,
      company: null,
      phoneNumber: null,
      message: null,
      verified: false,
    },
    contactError: {
      name: false,
      email: false,
      company: false,
      phoneNumber: false,
      message: false,
      verified: false,
    },
    contactErrorMessage: {
      name: new Array(),
      email: new Array(),
      company: new Array(),
      phoneNumber: new Array(),
      message: new Array(),
      verified: new Array(),
    },

    rules: {
      nameRule: [() => !!this.contactMessage.name || "This field is required"],
      emailRule: [
        () => !!this.contactMessage.email || "This field is required",
      ],
      phoneRule: [
        () => !!this.contactMessage.phone || "This field is required",
      ],
      messageRule: [
        () => !!this.contactMessage.message || "This field is required",
      ],
    },
    UIController: {
      loader: {
        btnLoader: false,
      },
      snackbars: {
        captchaSnackBar: false,
        successSnackBar: false,
        errorSnackBar: false,
      },
    },
  }),
};
</script>

<style scoped>
.section--form {
  background: linear-gradient(
    180deg,
    var(--secondary-color-lighten) 18.75%,
    var(--primary-color-lighten) 100%
  );
  padding-bottom: 7rem;
  padding-inline: 2rem;
}

@media only screen and (min-width: 1280px) {
  .section--form {
    padding-inline: none;
  }
}

.form__info {
  padding-top: 3rem;
}

.form__sub-title {
  font-size: 1.2rem;
}

.form__title {
  font-size: 1.7rem;
  margin-inline: auto;
  margin-block: 0.2rem;
}

.form__description {
  margin-inline: auto;
}

@media only screen and (min-width: 1280px) {
  .form__description {
    width: 40%;
    margin-inline: auto;
  }
}

.form__container {
  padding: 2rem 1.5rem;
  margin-inline: auto;
  margin-top: 3rem;
  max-width: 700px;
  border-radius: 0rem 0rem 0.875rem 0.875rem;
  background: rgba(163, 222, 255, 0.24);
}

@media only screen and (min-width: 1280px) {
  .form__container {
    padding: 2rem 5rem;
    margin-inline: auto;
    margin-top: 3rem;
    max-width: 700px;
    border-radius: 0rem 0rem 0.875rem 0.875rem;
    background: rgba(163, 222, 255, 0.24);
  }
}

@media only screen and (min-width: 2000px) {
  .form__container {
    max-width: 1000px;
  }
}

.button-container {
  text-align: center;
}

@media only screen and (min-width: 1280px) {
  .button-container {
    text-align: right;
  }
}

.submit-btn {
  color: var(--secondary-color-lighten) !important;
  text-transform: capitalize;
  letter-spacing: 0px;
  border-radius: 0.5rem;
  width: 100%;
}

@media only screen and (min-width: 1280px) {
  .submit-btn {
    width: auto;
  }
}
</style>
