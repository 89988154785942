<template>
  <div>
    <v-btn
      size="small"
      elevation="0"
      class="o400"
      variant="tonal"
      :append-icon="UIController.share.shareIcon"
      @click="share"
      :disabled="UIController.share.disabled"
      >Share</v-btn
    >
    <v-alert
      class="mt-3"
      v-if="UIController.share.showMessage"
      :text="UIController.share.shareMessage"
      type="warning"
      variant="tonal"
      size="small"
    ></v-alert>
  </div>
</template>
<script setup>
import { defineProps, reactive } from 'vue';

const props = defineProps(['title']);

const UIController = reactive({
  share: {
    canShare: false,
    shareMessage: '',
    showMessage: false,
    messageType: 'warning',
    shareIcon: 'mdi-share-variant',
    disabled: false,
  },
});

const share = async () => {
  const shareData = {
    title: props.title,
    url: window.location.href,
  };

  try {
    if (navigator.canShare) {
      await navigator.share(shareData);
      UIController.share.shareIcon = 'mdi-check';
      setTimeout(() => {
        UIController.share.shareIcon = 'mdi-share-variant';
      }, 3000);
    } else if (navigator.clipboard) {
      await navigator.clipboard.writeText(shareData.url);
      UIController.share.shareIcon = 'mdi-check';
      setTimeout(() => {
        UIController.share.shareIcon = 'mdi-share-variant';
      }, 3000);
    } else {
      UIController.share.showMessage = true;
      UIController.share.shareMessage =
        'This Browser does not allow Share feature';
      UIController.share.disabled = true;
    }
  } catch (e) {
    console.error('Error Ocurred');
  }
};
</script>
