<template>
  <section class="section--main">
    <div class="section__container">
      <h2 class="section__title text-left dms700">
        Benefits Of Working With Us
      </h2>
      <p class="section__description text-left m500 mb-15">
        In addition to the benefits associated with being a part of a
        multinational organization and an opportunity to work alongside talented
        coworkers, you will also be entitled to following benefits:
      </p>
      <!-- Mobile View -->
      <!-- R0 -->
      <v-row justify="left" class="section__row d-flex d-lg-none" justify-xs="center" justify-sm="start"
        justify-md="start" justify-lg="start">
        <!-- C2 -->
        <v-col class="section__col" cols="6" sm="4" md="4" lg="2">
          <div class="section__container_">
            <v-img eager class="section__img" :src="require('@/assets/img/hwb.svg')"></v-img>
            <p class="section__text dms600">Health and Wellness Benefits</p>
          </div>
        </v-col>
        <!-- C3 -->
        <v-col class="section__col" cols="6" sm="4" md="4" lg="2">
          <div class="section__container_">
            <v-img eager class="section__img" :src="require('@/assets/img/rb.svg')"></v-img>
            <p class="section__text dms600">Retirement Benefits</p>
          </div>
        </v-col>
        <!-- C4 -->
        <v-col class="section__col" cols="6" sm="4" md="4" lg="2">
          <div class="section__container_">
            <v-img eager class="section__img" :src="require('@/assets/img/fwa.svg')"></v-img>
            <p class="section__text dms600">Flexible Work Arrangements</p>
          </div>
        </v-col>
        <!-- C5 -->
        <v-col class="section__col" cols="6" sm="4" md="4" lg="2">
          <div class="section__container_">
            <v-img class="section__img" :src="require('@/assets/img/pd.svg')"></v-img>
            <p class="section__text dms600">Professional Development</p>
          </div>
        </v-col>
        <!-- C1 -->
        <v-col class="section__col" cols="6" sm="4" md="4" lg="2">
          <div class="section__container_">
            <v-img eager class="section__img" :src="require('@/assets/img/pfl.svg')"></v-img>
            <p class="section__text dms600">Parental and Family Leave</p>
          </div>
        </v-col>
        <!-- C2 -->
        <v-col class="section__col" cols="6" sm="4" md="4" lg="2">
          <div class="section__container_">
            <v-img eager class="section__img" :src="require('@/assets/img/erp.svg')"></v-img>
            <p class="section__text dms600">Employee Referral Program</p>
          </div>
        </v-col>
        <!-- C3 -->
        <!-- <v-col class="section__col" cols="6" sm="4" md="4" lg="2">
          <div class="section__container_">
            <v-img eager class="section__img" :src="require('@/assets/img/cb.svg')"></v-img>
            <p class="section__text dms600">Commuter Benefits</p>
          </div>
        </v-col> -->
        <!-- C4 -->
        <v-col class="section__col" cols="6" sm="4" md="4" lg="2">
          <div class="section__container_">
            <v-img eager class="section__img" :src="require('@/assets/img/sra.svg')"></v-img>
            <p class="section__text dms600">
              Social and Recreational Activities
            </p>
          </div>
        </v-col>
      </v-row>
      <!-- Desktop View -->
      <!-- R1 -->
      <v-row justify="start" class="section__row d-none d-lg-flex">
        <!-- C2 -->
        <v-col class="section__col" cols="6" sm="3" lg="2">
          <div class="section__container_">
            <v-img eager class="section__img" :src="require('@/assets/img/hwb.svg')"></v-img>
            <p class="section__text dms600">Health and Wellness Benefits</p>
          </div>
        </v-col>
        <!-- C3 -->
        <v-col class="section__col" cols="6" sm="3" lg="2">
          <div class="section__container_">
            <v-img eager class="section__img" :src="require('@/assets/img/rb.svg')"></v-img>
            <p class="section__text dms600">Retirement Benefits</p>
          </div>
        </v-col>
        <!-- C4 -->
        <v-col class="section__col" cols="6" sm="3" lg="2">
          <div class="section__container_">
            <v-img eager class="section__img" :src="require('@/assets/img/fwa.svg')"></v-img>
            <p class="section__text dms600">Flexible Work Arrangements</p>
          </div>
        </v-col>
        <!-- C5 -->
        <v-col class="section__col" cols="6" sm="3" lg="2">
          <div class="section__container_">
            <v-img class="section__img" :src="require('@/assets/img/pd.svg')"></v-img>
            <p class="section__text dms600">Professional Development</p>
          </div>
        </v-col>
        <!-- C1 -->
        <v-col class="section__col" cols="6" sm="3" lg="2">
          <div class="section__container_">
            <v-img eager class="section__img" :src="require('@/assets/img/pfl.svg')"></v-img>
            <p class="section__text dms600">Parental and Family Leave</p>
          </div>
        </v-col>
        <v-col class="section__col" cols="6" sm="3" lg="2">
          <div class="section__container_">
            <v-img eager class="section__img" :src="require('@/assets/img/erp.svg')"></v-img>
            <p class="section__text dms600">Employee Referral Program</p>
          </div>
        </v-col>
        <!-- C3 -->
        <!-- <v-col class="section__col" cols="6" sm="3" lg="2">
          <div class="section__container_">
            <v-img eager class="section__img" :src="require('@/assets/img/cb.svg')"></v-img>
            <p class="section__text dms600">Commuter Benefits</p>
          </div>
        </v-col> -->
        <!-- C4 -->
        <v-col class="section__col" cols="6" sm="3" lg="2">
          <div class="section__container_">
            <v-img eager class="section__img" :src="require('@/assets/img/sra.svg')"></v-img>
            <p class="section__text dms600">
              Social and Recreational Activities
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: "MainSection",
};
</script>

<style scoped>
.section--main {
  background-color: var(--white-1);
  padding-top: 2rem;
  padding-inline: 2rem;
  padding-bottom: 3rem;
}

.section__title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 1280px) {
  .section__description {
    margin-inline: auto;
  }
}

.section__row {
  max-width: 1400px;
  margin-inline: auto;
}

@media only screen and (min-width: 1280px) {
  .section__row {
    margin-top: 3rem;
  }
}

.section__col {
  height: 100%;
}

.section__container_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--light-1);
  padding: 2rem;
  min-height: 15rem;
  margin-inline: auto;
  border-radius: 1rem;
  /* transition: box-shadow 100ms ease-in-out; */
}

/* @media only screen and (min-width: 1280px) {
  .section__container_:hover {
    -webkit-animation: shadow-drop-2-bottom 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: shadow-drop-2-bottom 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
} */

.section__img {
  margin-bottom: 2rem;
  width: 80px;
  aspect-ratio: 1/1;
}

.section__text {
  /* color: var(--primary-color-lighten); */
  font-size: 0.85rem;
  text-transform: capitalize;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-25 14:22:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shadow-drop-2-bottom
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-2-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    -webkit-transform: translateZ(50px) translateY(-12px);
    transform: translateZ(50px) translateY(-12px);
    -webkit-box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
    box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

@keyframes shadow-drop-2-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    -webkit-transform: translateZ(50px) translateY(-12px);
    transform: translateZ(50px) translateY(-12px);
    -webkit-box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
    box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
</style>
