<template>
  <div class="for-job-seekers" id="view">
    <hero-section
      :title="title"
      :subTitle="subTitle"
      :description="description"
      :imageName="imageName"
      :showBlueWaves3="true"
    />
    <benefits-section />
    <developer-stories></developer-stories>
    <global-contact-section
      :titleSans="'Become a'"
      :titleSerif="'DIGU Verified Professional'"
      :hasSolidButton="true"
      :solidButtonText="'Learn More'"
      :solidButtonUrl="'/verified-badges'"
    />
  </div>
</template>

<script>
import HeroSection from '@/components/partials/HeroSection.vue';
import BenefitsSection from './Sections/BenefitsSection.vue';
import GlobalContactSectionVue from '@/components/partials/GlobalContactSection.vue';
import DeveloperStories from './Sections/DeveloperStories.vue';
export default {
  name: 'ForDevelopers',
  components: {
    'hero-section': HeroSection,
    'benefits-section': BenefitsSection,
    'global-contact-section': GlobalContactSectionVue,
    'developer-stories': DeveloperStories,
  },
  data: () => ({
    title: `<span class="title--special" style="display: flex;align-items: center; flex-wrap: wrap;"><span class="title__text">Career Growth</span>
          <span class="title__dot"
            >●</span
          >
          <span class="title__text">Higher Pay</span>
          <span class="title__dot"
            >●</span
          >
          <span class="title__text">Enhanced Living</span></span>`,
    subTitle: 'Why DIGU for Career Opportunities',
  }),
  created() {
    document.getElementById('loaderSection').classList.add('d-none');
  },
};
</script>
