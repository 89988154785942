<template>
  <section class="section--team">
    <div class="section__container">
      <div class="section__info">
        <h2 class="section__title dms700">Meet The Team</h2>
      </div>
      <v-row class="section__row" justify-sm="left" justify-md="center">
        <v-col class="section__col ps-0" cols="6" sm="3" md="2" xl="2">
          <div class="card--team">
            <div class="card__header header--img member-1">
              <v-icon class="link-indicator">mdi-link</v-icon>
              <a
                href="https://www.linkedin.com/in/sunbidshrestha/"
                target="_blank"
                class="card__link"
                ><v-img
                  :src="require('@/assets/img/linkedin.svg')"
                  eager
                  class="card__link-img"
                  width="100"
                ></v-img
              ></a>
            </div>
            <div class="card__body">
              <p class="member__name">Sunbid Shrestha</p>
              <p class="member__position">CEO and Co-Founder</p>
            </div>
          </div>
        </v-col>
        <v-col class="section__col ps-0" cols="6" sm="3" md="2" xl="2">
          <div class="card--team">
            <div class="card__header header--img member-2">
              <v-icon class="link-indicator">mdi-link</v-icon>
              <a
                href="https://www.linkedin.com/in/sudhir-b-shrestha/"
                target="_blank"
                class="card__link"
                ><v-img
                  eager
                  :src="require('@/assets/img/linkedin.svg')"
                  class="card__link-img"
                ></v-img
              ></a>
            </div>
            <div class="card__body">
              <p class="member__name">Sudhir Shrestha</p>
              <p class="member__position">COO and Co-Founder</p>
            </div>
          </div>
        </v-col>
        <v-col class="section__col ps-0" cols="6" sm="3" md="2" xl="2">
          <div class="card--team">
            <div class="card__header header--img member-3">
              <v-icon class="link-indicator">mdi-link</v-icon>
              <a
                href="https://www.linkedin.com/in/avanishshrestha/"
                target="_blank"
                class="card__link"
                ><v-img
                  eager
                  :src="require('@/assets/img/linkedin.svg')"
                  class="card__link-img"
                ></v-img
              ></a>
            </div>
            <div class="card__body">
              <p class="member__name">Avanish Shrestha</p>
              <p class="member__position mx-1">
                VP of IT and Co-Founder
              </p>
            </div>
          </div>
        </v-col>
        <v-col class="section__col ps-0" cols="6" sm="3" md="2" xl="2">
          <div class="card--team">
            <div class="card__header header--img member-4">
              <v-icon class="link-indicator">mdi-link</v-icon>
              <a
                href="https://www.linkedin.com/in/darshanshakya/"
                target="_blank"
                class="card__link"
                ><v-img
                  eager
                  :src="require('@/assets/img/linkedin.svg')"
                  class="card__link-img"
                ></v-img
              ></a>
            </div>
            <div class="card__body">
              <p class="member__name">Darshan Shakya</p>
              <p class="member__position mx-1">
                VP of Product and Co-Founder
              </p>
            </div>
          </div>
        </v-col>
        <v-col class="section__col ps-0" cols="6" sm="3" md="2" xl="2">
          <div class="card--team">
            <div class="card__header header--img member-5">
              <v-icon class="link-indicator">mdi-link</v-icon>
              <a
                href="https://www.linkedin.com/in/shakyaaman/"
                target="_blank"
                class="card__link"
                ><v-img
                  eager
                  :src="require('@/assets/img/linkedin.svg')"
                  class="card__link-img"
                ></v-img
              ></a>
            </div>
            <div class="card__body">
              <p class="member__name">Aman Shakya</p>
              <p class="member__position mx-1">VP of Marketing</p>
            </div>
          </div>
        </v-col>
        <v-col class="section__col ps-0" cols="6" sm="3" md="2" xl="2">
          <div class="card--team">
            <div class="card__header header--img member-6">
              <v-icon class="link-indicator">mdi-link</v-icon>
              <a
                href="https://www.linkedin.com/in/abhshr89/"
                target="_blank"
                class="card__link"
                ><v-img
                  eager
                  :src="require('@/assets/img/linkedin.svg')"
                  class="card__link-img"
                ></v-img
              ></a>
            </div>
            <div class="card__body">
              <p class="member__name">Abhishek Shrestha</p>
              <p class="member__position mx-1">Country Director - Nepal</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TeamSection',
};
</script>

<style scoped>
.section--team {
  padding-block: 3rem;
}

.section__info {
  text-align: center;
  max-width: 1000px;
  margin-inline: auto;
}

.section__subtitle {
  font-size: 1.25rem;
}

@media only screen and (min-width: 1280px) {
  .section__subtitle {
    text-align: center;
  }
}

.section__title {
  color: var(--accent-red);
  font-size: 2rem;
  margin-bottom: 1rem;
  letter-spacing: 0px;
  line-height: 150%;
}

@media only screen and (min-width: 1280px) {
  .section__title {
    font-size: 2rem;
    text-align: center;
    line-height: 120%;
  }
}

.section__row {
  max-width: 1400px;
  margin-inline: auto;
  margin-block: 1rem 0rem;
}

@media only screen and (min-width: 700px) {
  .section__row {
    margin-block: 4rem 0rem;
  }
}

/* .section__col {
  margin-block: 2rem;
} */

@media only screen and (max-width: 600px) {
  .section__col {
    margin-block: 0rem !important;
    padding: 6px !important;
    padding-right: 0px !important;
  }
}

@media only screen and (min-width: 1280px) {
  .section__col {
    margin-block: initial;
    padding: 12px !important;
  }
}

.card--team {
  border-radius: 1rem;
  overflow: hidden;
  height: 100%;
  background-color: var(--gray-3);
  margin-inline: auto;
}

@media only screen and (max-width: 600px) {
  .card--team {
    max-width: 200px;
    /* border-radius: 1rem; */
  }
}

@media only screen and (min-width: 600px) {
  .card--team {
    width: 100%;
    max-width: 330px;
  }
}

.card__header.header--img {
  position: relative;
  display: block;
  height: 150px;
  width: 100%;
  background-position: top;
  background-size: cover;
}

@media only screen and (max-width: 600px) {
  .card__header.header--img {
    height: 150px;
  }
}

@media only screen and (min-width: 1280px) {
  .card__header.header--img {
    height: 150px;
  }
}

@media only screen and (min-width: 1920px) {
  .card__header.header--img {
    height: 200px;
  }
}

.card__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.card__link:hover {
  opacity: 1;
}

.card__link-img {
  max-width: 50px;
  background-position: bottom;
}

/* @media only screen and (max-width: 600px) {
  .card__link-img {
    max-width: 50px;
  }
} */

.card__body {
  padding-block: 1.5rem;
}

@media only screen and (max-width: 600px) {
  .card__body {
    padding-block: 1rem;
  }
}

.member-1 {
  background-image: url('@/assets/img/Sunbid.webp');
}

.member-2 {
  background-image: url('@/assets/img/Sudhir.webp');
}

.member-3 {
  background-image: url('@/assets/img/Avanish.webp');
}

.member-4 {
  background-image: url('@/assets/img/Darshan.webp');
}

.member-5 {
  background-image: url('@/assets/img/Aman.webp');
}
.member-6 {
  background-image: url('@/assets/img/Abhishek.webp');
}

.member__name {
  font-size: 0.65rem;
  text-transform: uppercase;
  color: var(--primary-color-lighten);
  font-family: 'DM Sans Bold', sans-serif;
}

@media only screen and (max-width: 600px) {
  .member__name {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 700px) {
  .member__name {
    font-size: 0.85rem !important;
  }
}

.member__position {
  font-size: 0.6rem;
}

@media only screen and (max-width: 600px) {
  .member__position {
    font-size: 0.85rem;
  }
}

@media only screen and (min-width: 700px) {
  .member__position {
    font-size: 0.7rem;
  }
}

/* utils */
.link-indicator {
  position: absolute;
  z-index: 100;
  color: var(--primary-color-lighten);
  top: 1rem;
  right: 1rem;
}

@media only screen and (min-width: 1280px) {
  .link-indicator {
    display: none;
  }
}
</style>
