<template>
  <section class="section--carousel">
    <div class="section__container">
      <p class="section__subtitle dms700 text-left pt-15">What can</p>
      <h2 class="section__title dms600 text-left mb-10">
        Our Employees Expect?
      </h2>
      <v-carousel class="carousel--desktop" :hide-delimiters="true">
        <template v-slot:prev="{ props }">
          <v-btn
            variant="link"
            elevation="0"
            @click="props.onClick"
            style="height: 100%; width: 64px"
          >
            <v-icon size="x-large">mdi-chevron-left</v-icon>
          </v-btn>
        </template>
        <template v-slot:next="{ props }">
          <v-btn
            variant="link"
            elevation="0"
            @click="props.onClick"
            style="height: 100%; width: 64px"
          >
            <v-icon size="x-large">mdi-chevron-right</v-icon>
          </v-btn>
        </template>
        <!-- Item 1 -->
        <v-carousel-item>
          <slide-one />
        </v-carousel-item>
        <!-- Item 2 -->
        <v-carousel-item>
          <slide-two />
        </v-carousel-item>
        <!-- Item 3 -->
        <v-carousel-item>
          <slide-three />
        </v-carousel-item>
        <!-- Item 4 -->
        <v-carousel-item>
          <slide-four />
        </v-carousel-item>
      </v-carousel>
      <carousel :wrap-around="true" :itemsToShow="1" class="carousel--mobile">
        <slide class="m-carousel-slide">
          <mobile-slide-one />
        </slide>
        <slide class="m-carousel-slide">
          <mobile-slide-two />
        </slide>
        <slide class="m-carousel-slide">
          <mobile-slide-three />
        </slide>
        <slide class="m-carousel-slide">
          <mobile-slide-four />
        </slide>
        <template #addons>
          <pagination />
        </template>
      </carousel>
    </div>
  </section>
</template>

<script>
// ? Desktop Slides
import SlideOne from "./Slides/SlideOne.vue";
import SlideTwo from "./Slides/SlideTwo.vue";
import SlideThree from "./Slides/SlideThree.vue";
import SlideFour from "./Slides/SlideFour.vue";

// ? Mobile Slides
import MobileSlideOne from "./Slides/Mobile/MobileSlideOne.vue";
import MobileSlideTwo from "./Slides/Mobile/MobileSlideTwo.vue";
import MobileSlideThree from "./Slides/Mobile/MobileSlideThree.vue";
import MobileSlideFour from "./Slides/Mobile/MobileSlideFour.vue";

// Mobile Carousel
import "vue3-carousel/dist/carousel.css";
import { Carousel, Pagination, Slide } from "vue3-carousel";

export default {
  name: "SmartworksCentral",
  components: {
    // * Desktop Slides
    "slide-one": SlideOne,
    "slide-two": SlideTwo,
    "slide-three": SlideThree,
    "slide-four": SlideFour,

    // * Mobile Slides
    "mobile-slide-one": MobileSlideOne,
    "mobile-slide-two": MobileSlideTwo,
    "mobile-slide-three": MobileSlideThree,
    "mobile-slide-four": MobileSlideFour,

    // * Mobile Carousel
    carousel: Carousel,
    slide: Slide,
    // navigation: Navigation,
    pagination: Pagination,
  },
};
</script>

<style scoped>
.section--carousel {
  background-color: var(--secondary-color-lightest);
  padding-bottom: 4rem;
}

@media only screen and (min-width: 1280px) {
  .section--carousel {
    padding-bottom: 3rem;
  }
}

.section__subtitle {
  font-size: 1.5rem;
}

@media only screen and (min-width: 1200px) {
  .section__subtitle {
    font-size: 2rem;
  }
}

.section__title {
  font-size: 1.75rem;
  color: var(--accent-red) !important;
}

@media only screen and (min-width: 1200px) {
  .section__title {
    font-size: 2.5rem;
  }
}

.carousel--mobile {
  align-items: stretch;
}

.carousel--desktop {
  display: none;
}

@media only screen and (min-width: 1280px) {
  .carousel--desktop {
    display: initial;
  }
  .carousel--mobile {
    display: none !important;
  }
}
</style>
