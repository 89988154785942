<template>
  <v-sheet color="#e3f5ff" height="100%">
    <div class="d-flex flex-column fill-height justify-center align-center">
      <v-row class="section__row" height="100%">
        <v-col
          class="section__col d-flex flex-column fill-height justify-center align-start"
          cols="12"
          sm="12"
          md="12"
          lg="6"
        >
          <h3 class="section__title-2 dms700 mb-4">
            Embracing the Future of Business:
          </h3>
          <p class="section__description-2 text-left m400">
            With DIGU Central, we are ready to make a significant impact in the
            tech industry, both locally and globally. By establishing a
            world-class office space in Kathmandu, we are positioning ourselves
            at the forefront of innovation and business excellence. Our
            commitment to providing a conducive work environment reflects our
            dedication to empowering our team and driving transformative
            outcomes for our clients.
          </p>
        </v-col>
        <v-col
          class="section__col d-flex flex-column"
          cols="12"
          sm="12"
          md="12"
          lg="6"
        >
          <v-img
            :src="
              require('@/assets/img/smartworks-central-carousel-slide-four.webp')
            "
            :eager="true"
            cover
            class="carousel__image ma-2"
            :height="400"
          >
          </v-img>
          <caption class="carousel__caption"></caption>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'SlideFour',
};
</script>

<style scoped>
.section__row {
  padding-inline: 6rem !important;
}

.section__subtitle {
  font-size: 2rem;
}
.section__title-2 {
  font-size: 2rem;
  text-align: left;
  line-height: 120%;
}

.carousel__image {
  border-radius: 2rem !important;
}

.carousel__caption {
  width: 100%;
  margin-bottom: 3rem;
}
</style>
