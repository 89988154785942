import axios from "axios";
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

// ? Get all Languages and Frameworks
const getLanguageFrameworkUtil = async () => {
  const apiUrl = process.env.VUE_APP_API_URL;
  const languageAndFramework = await axios
    .get(`${apiUrl}/v1/public/utils/language-framework`)
    .then((response) => {
      return {
        status: response.status,
        data: response.data.data,
      };
    });

  return languageAndFramework;
};

export default {
  getLanguageFrameworkUtil,
};
