<template>
  <section class="section--vision">
    <div class="section__container mt-12">
      <v-row class="section__row">
        <v-col
          class="section__col d-none d-md-block img-container"
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <keep-alive>
            <v-img
              eager
              :src="require('@/assets/img/about-vision.webp')"
              cover
              class="section__image"
            ></v-img>
          </keep-alive>
        </v-col>
        <v-col
          class="section__col text-container"
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <h2 class="section__title dms700 section__title">Our Vision</h2>
          <keep-alive>
            <v-img
              eager
              :src="require('@/assets/img/about-vision.webp')"
              cover
              class="m-section__image"
            ></v-img>
          </keep-alive>
          <div class="section__description-container m500">
            <p class="section__description">
              Our vision is to make Nepal the premier hub for tech
              professionals, connecting local talent with global opportunities
              and providing our customers with exceptional talent and innovative
              solutions.
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'VisionSection',
};
</script>

<style scoped>
.section--vision {
  background-color: var(--secondary-color-lightest);
  padding-block: 3rem;
}

.section__row {
  padding-inline: 0px;
}

.section__col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media only screen and (min-width: 1280px) {
  .text-container {
    text-align: left;
  }
}

.section__title {
  text-align: left;
  color: var(--accent-red);
}

@media only screen and (min-width: 1200px) {
  .section__title {
    text-align: left;
    line-height: 120%;
  }
}

@media only screen and (min-width: 1280px) {
  .section__description-container {
    width: 80%;
  }
}

.section__image {
  border-radius: 0.8rem;
  margin-right: auto;
  aspect-ratio: 4/3;
}

.m-section__image {
  border-radius: 1rem;
  margin-block: 2rem;
  max-height: 200px;
}

@media only screen and (min-width: 700px) {
  .m-section__image {
    max-height: 300px;
  }
}

.section__description {
  margin-top: 0.5rem;
  text-align: left;
}

@media only screen and (min-width: 1200px) {
  .section__description {
    text-align: left;
  }
}

@media only screen and (min-width: 960px) {
  .m-section__image {
    display: none;
  }
}
</style>
