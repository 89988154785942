<template>
  <div class="new-header">
    <div class="section__container"> <v-row class="new-header-section">
        <v-col cols="12" md="6" lg="6">
          <p class="header__title">
            Inspiring<br />
            Dreams For<br />
            <span class="text-accent">A Better Tomorrow</span>
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <keep-alive>
            <v-img eager :src="require('@/assets/img/cares-hero.webp')" :lazy-src="require('@/assets/img/lazy-img.jpg')"
              class="section__img"></v-img>
          </keep-alive>
        </v-col>
      </v-row></div>
  </div>
</template>

<script>
export default {
  name: "NeuHeaderSektion"
}
</script>

<style scoped>
.new-header {
  background-color: var(--light-1);
  padding: 2rem;
}

.header__title {
  font-family: 'DM Sans Bold', sans-serif;
  font-size: 3rem;
  text-align: left;
  line-height: 3.25rem;
  color: var(--dark-1);
}

.header__title .text-accent {
  color: var(--accent-red)
}
</style>