<template>
  <section class="section--benefits">
    <div class="section__container">
      <!-- ROW 1 -->
      <v-row class="section__row">
        <v-col class="section__col" cols="12" sm="6" md="6" lg="4" xl="4">
          <keep-alive>
            <v-img
              :src="
                require('@/assets/img/unlocking-opportunities-with-top-global-innovators.webp')
              "
              eager
              class="section__img"
            ></v-img>
          </keep-alive>
        </v-col>
        <v-col
          class="section__col info__col"
          cols="12"
          sm="6"
          md="6"
          lg="8"
          xl="8"
        >
          <div class="col__info">
            <p class="section__title-2 dms700">
              Unlocking Opportunities with Top Global Innovators
            </p>
            <p class="section__description m500">
              Joining DIGU offers a unique chance to collaborate with some of
              the world's leading and most innovative companies. This invaluable
              experience allows you to immerse yourself in cutting-edge
              technologies and gain exposure to the forefront of technological
              advancements.
            </p>
          </div>
        </v-col>
      </v-row>

      <!-- Row 2 -->
      <v-row class="section__row">
        <v-col
          class="section__col order-last order-sm-first info__col"
          cols="12"
          sm="6"
          md="6"
          lg="8"
          xl="8"
        >
          <div class="col__info">
            <p class="section__title-2 dms700">
              Competitive Salaries and Enhanced Living Standards
            </p>
            <p class="section__description m500">
              At DIGU, we believe in recognizing talent and rewarding it
              accordingly. Our employees receive highly competitive salaries
              allowing them to elevate their standard of living while enjoying a
              fulfilling career.
            </p>
          </div>
        </v-col>
        <v-col class="section__col">
          <keep-alive>
            <v-img
              :src="
                require('@/assets/img/compeititve-salaries-and-enhanced-living-standards.webp')
              "
              eager
              class="section__img"
            ></v-img>
          </keep-alive>
        </v-col>
      </v-row>

      <!-- Row 3 -->
      <v-row class="section__row">
        <v-col class="section__col" cols="12" sm="6" md="6" lg="4" xl="4">
          <keep-alive>
            <v-img
              :src="
                require('@/assets/img/a-secure-and-productive-work-environment.webp')
              "
              eager
              class="section__img"
            ></v-img>
          </keep-alive>
        </v-col>
        <v-col
          class="section__col info__col"
          cols="12"
          sm="6"
          md="6"
          lg="8"
          xl="8"
        >
          <div class="col__info">
            <p class="section__title-2 dms700">
              Secure and Productive Work Environment
            </p>
            <p class="section__description m500">
              Our facility operates 24/7 and ensures a secure, conducive space
              for your productivity. With high-speed internet, well-equipped
              workstations, a fully stocked kitchen, and a recreational area, we
              ensure that you have everything you need to excel at work and
              unwind.
            </p>
          </div>
        </v-col>
      </v-row>

      <!-- Row 4 -->
      <v-row class="section__row">
        <v-col
          class="section__col order-last order-sm-first info__col"
          cols="12"
          sm="6"
          md="6"
          lg="8"
          xl="8"
        >
          <div class="col__info">
            <p class="section__title-2 dms700">
              Empowering Your Career Trajectory
            </p>
            <p class="section__description m500">
              DIGU is committed to your professional growth. Through tailored
              training, development programs, and the opportunity to work with
              global companies, you can elevate your skills, expertise, and
              career to new heights. Embrace the chance to advance your journey
              and reach your full potential.
            </p>
          </div>
        </v-col>
        <v-col class="section__col">
          <v-img
            :src="
              require('@/assets/img/empowering-your-career-trajectory.webp')
            "
            eager
            class="section__img"
          ></v-img>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BenefitsSection',
};
</script>

<style scoped>
.section--benefits {
  background-color: var(--secondary-color-lighten);
  padding-block: 4rem;
}

@media only screen and (min-width: 700px) {
  .section--benefits {
    padding-block: 2rem;
  }
}

.section__header {
  margin-bottom: 3rem;
}

.section__subtitle {
  font-size: 1.25rem;
  color: var(--accent-red);
  text-align: left;
}

@media only screen and (min-width: 1200px) {
  .section__subtitle {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 1200px) {
  .section__subtitle {
    font-size: 1.5rem;
  }
}

.section__title {
  line-height: 150% !important;
  font-size: 1.25rem !important;
  text-align: left;
}

@media only screen and (min-width: 960px) {
  .section__title {
    font-size: 2rem !important;
  }
}

.section__row {
  margin-bottom: 3rem;
}

@media only screen and (min-width: 1200px) {
  .section__row {
    margin-block: 4rem;
  }
}

.section__col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section__img {
  height: 250px;
  width: 100%;
}

@media only screen and (min-width: 1280px) {
  .section__img {
    max-width: 35rem;
    aspect-ratio: 4/3;
  }
}

.section__title-2 {
  text-align: left;
  line-height: 120%;
}

@media only screen and (min-width: 1200px) {
  .section__title-2 {
    margin-bottom: 1rem;
  }
}

.col__info {
  text-align: left;
}

@media only screen and (min-width: 1280px) {
  .col__info {
    text-align: left;
    width: 80%;
  }
}

@media only screen and (min-width: 1280px) {
  .info__col:nth-child(odd) {
    justify-content: flex-start;
  }

  .info__col:nth-child(even) {
    justify-content: flex-end;
  }
}
</style>
