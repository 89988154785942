<template>
  <div class="care--page" id="view">
    <hero-section />
    <!-- <main-section /> -->
    <info-section />
    <program-section />
    <professional-development-section />
    <carousel-section />
  </div>
</template>

<script>
// Global Components
// import HeroSection from "@/components/partials/HeroSection.vue";
import NeuHeaderSektion from './Sections/NeuHeaderSektion.vue';

// Local Components
import CarouselSection from './Sections/CarouselSection.vue';
// import MainSection from "./Sections/MainSection.vue";
import InfoSection from './Sections/InfoSection.vue';
import ProgramsSection from './Sections/ProgramsSection.vue';
import ProfessionalDevelopmentSection from './Sections/ProfessionalDevelopmentSection.vue';
export default {
  components: {
    'hero-section': NeuHeaderSektion,
    'carousel-section': CarouselSection,
    // "main-section": MainSection,
    'info-section': InfoSection,
    'program-section': ProgramsSection,
    'professional-development-section': ProfessionalDevelopmentSection,
  },
  data: () => ({
    title: 'DIGU Cares',
    subTitle:
      'Creating Opportunities &middot; Changing Lives &middot; Giving Back for a Better Future',
  }),
  created() {
    document.getElementById('loaderSection').classList.add('d-none');
  },
};
</script>
