<script setup>
import ClientCard from '@/components/partials/ClientCard.vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import { ref, defineProps } from 'vue';

const props = defineProps({
  hasPB: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const clients = ref([
  {
    name: 'Ryan Schultz',
    title: 'Founder & CEO',
    company: 'DIVACS, Inc.',
    hasImage: true,
    message: `Working with DIGU has been an absolute pleasure. Their team of developers consistently impresses with their expertise, professionalism, and dedication to delivering top-notch solutions. Their innovative approach and attention to detail have greatly contributed to DIVACS' success. Collaborating with DIGU has truly elevated our expectations, and I look forward to many more projects with them in the future.`,
  },
]);

const carouselModel = ref(0);
</script>
<template>
  <div :class="{ 'pb-10': props.hasPB }" class="stories-container">
    <h3 class="dms700 title">
      What Do Our <span class="highlight">Customers</span> Say?
    </h3>
    <section class="client-stories">
      <v-row justify="center" align="center" class="desktop-stories">
        <v-col
          v-for="(client, index) in clients"
          :key="index"
          cols="12"
          xs="12"
          md="4"
          class="d-flex justify-center"
        >
          <ClientCard
            :name="client.name"
            :title="client.title"
            :message="client.message"
            :company="client.company"
            :hasImage="client.hasImage"
          ></ClientCard>
        </v-col>
      </v-row>
    </section>
    <Carousel
      class="mobile-stories"
      v-model="carouselModel"
      :wrap-around="true"
      :items-to-show="1"
    >
      <Slide
        v-for="(client, index) in clients"
        :key="index"
        class="mobile-stories__slide"
      >
        <div class="pa-3">
          <ClientCard
            :name="client.name"
            :title="client.title"
            :message="client.message"
            :company="client.company"
            :hasImage="client.hasImage"
          ></ClientCard>
        </div>
      </Slide>
      <template #addons>
        <Pagination
          style="
            background-color: var(--light-1);
            margin-top: 0px;
            margin-bottom: 0.5rem;
          "
        />
      </template>
    </Carousel>
  </div>
</template>
<style scoped>
.stories-container {
  background-color: var(--light-1);
}
.client-stories {
  display: none;
  background-color: var(--light-1);
}

.title {
  background-color: var(--light-1);
  padding-block: 2rem;
  line-height: 1.75rem;
  font-size: 1.5rem;
}

.desktop-stories {
  display: none;
  width: 95%;
  max-width: 1400px;
  margin-inline: auto;
}

.mobile-stories__slide {
  background-color: var(--light-1) !important;
}

@media only screen and (min-width: 1280px) {
  .desktop-stories,
  .client-stories {
    display: flex;
  }

  .mobile-stories {
    display: none;
  }
}

.highlight {
  color: var(--accent-red);
}
</style>
