<template>
  <section class="carousel-section">
    <keep-alive>
      <carousel :items-to-show="sliderPerScreen" :wrap-around="true">
        <slide
          v-for="slide in slides"
          :key="slide.id"
          class="carousel-slide"
          :class="`slide__card ${slide.class}`"
        >
          <div class="section__container-2 mt-auto">
            <div class="carousel-card">
              <h3 class="carousel__title ws500 section__title-2">
                {{ slide.title }}
              </h3>
              <p class="carousel__description m500 section__description">
                {{ slide.description }}
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </keep-alive>
  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

export default {
  name: 'CarouselSection',
  components: {
    carousel: Carousel,
    slide: Slide,
    navigation: Navigation,
  },
  created() {
    if (window.screen.width > 1023) {
      this.sliderPerScreen = 1.25;
    } else [(this.sliderPerScreen = 1)];
  },
  data: () => ({
    sliderPerScreen: 1.25,
    slides: [
      {
        id: 1,
        title: 'Custom Software Development',
        description:
          'If you require custom software development to meet your specific business needs, hire DIGU developers to support your organization.',
        class: 'development',
      },
      {
        id: 2,
        title: 'Web And Mobile Application Development',
        description:
          'Web and mobile applications can enhance your online presence and help you better engage with your prospects and customers ultimately driving your revenue. DIGU developers can develop and maintain all your applications.',
        class: 'design',
      },
      {
        id: 3,
        title: 'Software Maintenance And Support',
        description:
          'Every organization leverages 3rd party or in-house business applications to support their day-to-day operations. DIGU developers can maintain and support your software.',
        class: 'project-management',
      },
      {
        id: 4,
        title: 'Big Data Analytics',
        description:
          'In today’s world, every organization needs software developers to collect, store, process, and analyze big data. You may need developers to build and maintain what your software architects design. DIGU can provide you with developer resources to support this need.',
        class: 'data-science',
      },
      {
        id: 5,
        title: 'Integration With Third Party Application',
        description:
          'Your business may require integration with 3rd party applications, such as payment gateways, social media platforms, and customer relationship management (CRM) systems. Our developers can help with the integration.',
        class: 'integration',
      },
      {
        id: 6,
        title: 'Cloud Computing Services',
        description:
          'Organizations require cloud computing services to store, manage, and access their data and applications. Our developers can provide you with these services.',
        class: 'cloud-service',
      },
    ],
  }),
};
</script>

<style scoped>
.slide__card {
  height: 40rem;
}

@media only screen and (min-width: 2000px) {
  .slide__card {
    height: 60rem;
  }
}

.carousel-slide {
  background-position: center;
}

@media only screen and (min-width: 1280px) {
  .carousel-slide {
    margin-inline: 0.1rem;
  }
}

.development {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url('@/assets/img/home-custom-software-development.webp');
  background-size: cover;
}
.cloud-service {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url('@/assets/img/home-cloud.webp');
  background-size: cover;
}
.design {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url('@/assets/img/home-mobile-and-web.webp');
  background-size: cover;
}
.data-science {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url('@/assets/img/home-big-data.webp');
  background-size: cover;
}
.project-management {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url('@/assets/img/project-management.webp');
  background-size: cover;
}
.integration {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url('@/assets/img/home-third-party.webp');
  background-size: cover;
}

.carousel-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem;
  height: 100%;
}

@media only screen and (min-width: 1200px) {
  .carousel-card {
    padding-inline: 0px;
    height: 100%;
  }
}

.carousel__title {
  color: var(--light-1);
  font-size: 2rem;
  width: 100%;
  text-align: left;
}

@media only screen and (min-width: 700px) {
  .carousel__title {
    width: 100%;
  }
}

.carousel__tags {
  color: var(--light-1);
  font-size: 1rem;
}

.carousel__description {
  color: var(--light-1);
  font-size: 1rem;
  width: 80%;
  text-align: left;
}

@media only screen and (min-width: 700px) {
  .carousel__description {
    width: 90%;
  }
}
</style>
