<script setup>
import { Carousel, Slide } from 'vue3-carousel';
import { ref } from 'vue';
import DeveloperFeedbackCard from './partials/DeveloperFeedbackCard.vue';

const feedbacks = ref([
  {
    name: 'Anwesh Dahal',
    message: `The DIGU Resume Building Workshop was a game changer! It helped me spot the weak spots in my resume and gave me some real eye opening insights into what the industry's looking for. Now I've got a better handle on how to structure things and where I need to shine.`,
    hasImage: true,
  },
  {
    name: 'Bishesh Dangol',
    message: `Attending the Resume Builder Workshop was incredibly beneficial. It opened my eyes to the critical areas where my resume needed improvement. The experts shared up-to-date industry standards and tips on how to make a resume more appealing to employers. I particularly appreciated the focus on structuring content for impact, which has equipped me with the tools to present my skills and experiences more effectively.`,
    hasImage: true,
  },
]);

const carouselModel = ref(0);
</script>
<template>
  <Carousel
    class="feedbacks"
    v-model="carouselModel"
    :wrap-around="true"
    :items-to-show="1"
  >
    <Slide
      v-for="(feedback, index) in feedbacks"
      :key="index"
      class="feedbacks__slide"
    >
      <div class="pa-3 w-100">
        <DeveloperFeedbackCard
          :message="feedback.message"
          :name="feedback.name"
          :has-image="feedback.hasImage"
        />
      </div>
    </Slide>
  </Carousel>
  <p class="text-right" style="font-size: 0.9rem; color: var(--gray-4)">
    Swipe to read more <v-icon>mdi-chevron-double-right</v-icon>
  </p>
</template>
<style scoped>
.feedbacks {
  height: 100%;
}

.feedbacks__slide {
  height: 100%;
}
</style>
