<template>
  <v-sheet color="#e3f5ff" height="100%">
    <div class="d-flex flex-column fill-height justify-center align-center">
      <v-row class="section__row" height="100%">
        <v-col
          class="section__col d-flex flex-column fill-height justify-center align-start"
          cols="12"
          sm="12"
          md="12"
          lg="6"
        >
          <h3 class="section__title-2 dms700 mb-4">
            A Serene Oasis Amidst Bustling Kathmandu
          </h3>
          <p class="section__description-2 text-left m400">
            Nestled within a 5-acre property owned by one of Kathmandu's oldest
            nurseries, The Standard Nursery, DIGU Central is strategically
            located in the heart of the valley. We are proud to have integrated
            our office with the lush greenery of the nursery, offering a
            tranquil escape from the hustle and bustle of the city. This unique
            setting allows our employees to recharge and find inspiration amidst
            nature's splendor.
          </p>
        </v-col>
        <v-col
          class="section__col d-flex flex-column"
          cols="12"
          sm="12"
          md="12"
          lg="6"
        >
          <keep-alive>
            <v-img
              :src="
                require('@/assets/img/smartworks-central-carousel-slide-one.webp')
              "
              :eager="true"
              cover
              class="carousel__image ma-2"
              :height="400"
            >
            </v-img>
          </keep-alive>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'SlideOne',
};
</script>

<style scoped>
.section__row {
  padding-inline: 6rem !important;
}
.section__subtitle {
  font-size: 2rem;
}
.section__title-2 {
  font-size: 2rem;
  text-align: left;
  line-height: 120%;
}

.carousel__image {
  border-radius: 2rem !important;
}

.carousel__caption {
  width: 100%;
  margin-bottom: 3rem;
}
</style>
