<template>
  <div class="page--about" id="view">
    <!-- DONE -->
    <!-- <hero-section
      :title="title"
      :subTitle="subTitle"
      :description="description"
      :imageName="imageName"
    /> -->
    <neu-hero-sektion />
    <about-digu />

    <vision-section />
    <mission-section />
    <goal-section />
    <team-section />
    <!-- <global-contact-section titleSans="Get in touch" titleSerif="With us!" :subTitle="'We can\'t wait to hear from you!'"
      :subDescription="'We\'re here to provide you with excellent service and support.'" :hasSolidButton="true"
      :solidButtonText="'Talk To Us'" :solidButtonUrl="'/contact'" /> -->
    <neu-footer-sektion />
  </div>
</template>

<script>
// Global Components
// import HeroSection from "@/components/partials/HeroSection.vue";
// import GlobalContactSectionVue from "@/components/partials/GlobalContactSection.vue";

// Local Components
import VisionSection from './Sections/VisionSection.vue';
import MissionSection from './Sections/MissionSection.vue';
import GoalSection from './Sections/GoalSection.vue';
import TeamSection from './Sections/TeamSection.vue';
import NeuHeroSektion from './Sections/NeuHeroSektion.vue';
import NeuFooterSektion from './Sections/NeuFooterSektion.vue';
import AboutDIGUVue from './Sections/AboutDIGU.vue';

export default {
  name: 'AboutVue',
  components: {
    // "hero-section": HeroSection,
    'neu-hero-sektion': NeuHeroSektion,
    'vision-section': VisionSection,
    'mission-section': MissionSection,
    'goal-section': GoalSection,
    'team-section': TeamSection,
    // "global-contact-section": GlobalContactSectionVue,
    'neu-footer-sektion': NeuFooterSektion,
    'about-digu': AboutDIGUVue,
  },
  data: () => ({
    subTitle: 'About Us',
    title: 'Who We Are',
    imageName: 'about-hero.webp',
    description:
      "We are entrepreneurs.<br />We are immigrants.<br />We are expatriates.<br /><br />Our unique stories of determination and purpose led us to co-found DIGU with a shared passion: \"TO ELEVATE\".<br /><br />ELEVATE our customers' products<br />ELEVATE our candidates' careers<br />ELEVATE our partners' outreach",
  }),
  created() {
    document.getElementById('loaderSection').classList.add('d-none');
  },
};
</script>

<style></style>
