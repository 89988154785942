<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  message: {
    type: String,
    required: false,
  },
  hasImage: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const imageNameFormatter = (name) => {
  const name_ = name.split(' ').join('_').toLowerCase();
  return name_;
};

const developerImage = computed(() => {
  return props.hasImage
    ? require(`@/assets/img/${imageNameFormatter(props.name)}.webp`)
    : `https://api.dicebear.com/8.x/initials/svg?seed=${props.name}`;
});
</script>
<template>
  <div class="developer-card">
    <div class="developer-card__header text-left">
      <v-icon class="quote-icon">mdi-format-quote-open</v-icon>
    </div>
    <p class="developer-card__message">
      {{ props.message || '' }}
    </p>
    <div class="developer-details">
      <v-row>
        <v-col cols="3" class="text-left d-flex align-items-center">
          <keep-alive>
            <img :src="developerImage" class="developer-image" height="75"
          /></keep-alive>
        </v-col>
        <v-col cols="9">
          <div class="details__section">
            <p class="developer-name text-left o600">{{ props.name || '' }}</p>
            <p class="developer-title text-left">{{ props.title || '' }}</p>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<style scoped>
.developer-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  background-color: var(--white-1);
  width: 100%;
  max-width: 500px;
  /* min-width: 400px; */
  min-height: 450px;
}

@media only screen and (min-width: 1280px) {
  .developer-card {
    border-radius: 1rem;
  }
}

.quote-icon {
  font-size: 3rem;
  color: var(--secondary-color-lighten-2);
}

.developer-card__message {
  font-family: 'Lora', serif;
  font-size: 0.9rem;
  color: var(--dark-1);
  font-weight: 500;
}

.developer-image {
  border-radius: 50px;
  box-shadow: 0px 0px 16px var(--shadow-1);
  object-fit: cover;
}

.developer-details {
  padding: 1rem;
}

.details__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-left: 1rem;
}

.developer-name {
  margin-block: 0px;
  font-size: 1rem;
}

.developer-title {
  font-size: 0.9rem;
}

.developer-card__footer {
  padding: 1rem;
}
</style>
