<template>
  <section class="section--developer-hiring">
    <keep-alive>
      <v-row class="developer-hiring__row">
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          class="developer-hiring__col col--developer"
          @click="hireADeveloper"
        >
          <div class="sub-section__container sub-section__container--left">
            <div class="dh__container">
              <span class="link-indicator" :class="{ hidden: !isTouch }">
                <v-icon icon="mdi-link"></v-icon>
              </span>
              <p class="dh__sub-title o500 section__subtitle">
                Hire a Professional
              </p>
              <h3 class="dh__title m500 section__title-2">
                Hire the right professional from our talent pool who have been
                verified by us and are interview-ready.
              </h3>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          class="developer-hiring__col col--hiring-company"
          @click="outSourceYourProject"
        >
          <div class="sub-section__container sub-section__container--right">
            <div class="dh__container">
              <span class="link-indicator" :class="{ hidden: !isTouch }">
                <v-icon icon="mdi-link"></v-icon>
              </span>
              <p class="dh__sub-title o500 section__subtitle">
                Outsource Your Project
              </p>
              <h3 class="dh__title m500 section__title-2">
                We have a team of highly skilled developers, project managers,
                and architects to support your technical projects.
              </h3>
            </div>
          </div>
        </v-col>
      </v-row>
    </keep-alive>
  </section>
</template>

<script>
export default {
  name: 'DeveloperAndHiringSection',
  beforeMount() {
    if ('ontouchstart' in window) {
      // TODO: Touch
      this.isTouch = true;
    }
  },
  data: () => ({
    isTouch: false,
  }),
  methods: {
    hireADeveloper: () => {
      window.location.href = '/hire-a-professional';
    },
    outSourceYourProject: () => {
      window.location.href = '/outsource-your-project';
    },
  },
};
</script>

<style scoped>
.section--developer-hiring {
  height: 90vh;
  width: 100vw;
  overflow: hidden;
}

@media only screen and (min-width: 700px) {
  .section--developer-hiring {
    height: 60vh;
  }
}

@media only screen and (min-width: 1024px) {
  .section--developer-hiring {
    height: 50vh;
  }
}

@media only screen and (min-width: 1200px) {
  .section--developer-hiring {
    height: 70vh;
  }
}

@media only screen and (min-width: 1600px) {
  .section--developer-hiring {
    height: 80vh;
  }
}

@media only screen and (min-width: 2000px) {
  .section--developer-hiring {
    height: 75vh;
  }
}

.developer-hiring__row {
  height: 90vh;
}

@media only screen and (min-width: 700px) {
  .developer-hiring__row {
    height: 60vh;
  }
}

@media only screen and (min-width: 1024px) {
  .developer-hiring__row {
    height: 50vh;
  }
}

@media only screen and (min-width: 1200px) {
  .developer-hiring__row {
    height: 70vh;
  }
}

@media only screen and (min-width: 1600px) {
  .developer-hiring__row {
    height: 100%;
  }
}

.developer-hiring__col {
  cursor: pointer;
  padding-block: 3rem;
}

@media only screen and (min-width: 700px) {
  .developer-hiring__col {
    cursor: pointer;
    padding-block: 5rem;
  }
}

.col--developer,
.col--hiring-company {
  position: relative;
  overflow: hidden;
}

.col--developer::before,
.col--hiring-company::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: filter 200ms ease-in-out;
  z-index: -1;
}

@media only screen and (min-width: 1200px) {
  .col--developer::before,
  .col--hiring-company::before {
    filter: saturate(20%);
  }
}

.col--developer::before {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.9) 77%
    ),
    url('@/assets/img/developer-section-bg.webp'), lightgray 50%;
  background-size: cover;
  background-position-y: 70%;
}

@media only screen and (min-width: 1280px) {
  .col--developer::before {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.9) 92.67%
      ),
      url('@/assets/img/developer-section-bg.webp'), lightgray 50%;
    background-size: cover;
  }
}

.col--hiring-company::before {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0,
      rgba(0, 0, 0, 0.9) 77%
    ),
    url('@/assets/img/hiring-company-section-bg.webp'), lightgray 50%;
  background-size: cover;
}

@media only screen and (min-width: 700px) {
  .col--hiring-company::before {
    background-position-y: 50%;
    background-position-x: center;
  }
}

@media only screen and (min-width: 1280px) {
  .col--hiring-company:before {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 6.77%,
        rgba(0, 0, 0, 0.9) 100%
      ),
      url('@/assets/img/hiring-company-section-bg.webp'), lightgray 50%;
    background-size: cover;
  }
}

.col--developer:hover::before,
.col--hiring-company:hover::before {
  filter: none;
}

.dh__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.col--developer .dh__container {
  align-items: left;
}

@media only screen and (min-width: 1025px) {
  .col--developer .dh__container {
    margin-left: auto;
    align-items: flex-start;
  }
}

.col--hiring-company .dh__container {
  align-items: left;
}

@media only screen and (min-width: 1025px) {
  .col--hiring-company .dh__container {
    margin-right: auto;
    align-items: flex-end;
  }
}

.dh__title {
  color: var(--light-1);
  font-size: 1.25rem !important;
  line-height: 120%;
  margin-bottom: 2rem;
}

.col--developer .dh__title {
  text-align: left;
}

@media only screen and (min-width: 700px) {
  .col--developer .dh__title {
    text-align: left;
  }
}

.col--hiring-company .dh__title {
  text-align: left;
}

@media only screen and (min-width: 960px) {
  .col--hiring-company .dh__title {
    text-align: right;
    margin-left: 1rem;
  }
}

.dh__sub-title {
  color: var(--accent-red);
  font-size: 1.2rem;
  text-align: left;
}

@media only screen and (min-width: 960px) {
  .col--hiring-company .dh__sub-title {
    text-align: right;
    margin-left: 1rem;
  }
}

.link-indicator {
  background-color: #fff;
  border-radius: 10rem;
  padding: 0.1rem;
  position: absolute;
  top: 30px;
  right: 30px;
}

.hidden {
  display: none !important;
}

.sub-section__container {
  height: 100%;
  max-width: 628px;
}
</style>
