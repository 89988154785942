<template>
  <div class="m-carousel-card">
    <v-row>
      <v-col class="d-none d-md-block" cols="12" xs="12" sm="12" md="6" lg="6">
        <keep-alive>
          <v-img
            :src="
              require('@/assets/img/smartworks-central-carousel-slide-one.webp')
            "
            cover
            :eager="true"
            :height="300"
            class="m-carousel__image ma-2"
          >
          </v-img>
        </keep-alive>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <keep-alive>
          <v-img
            :src="
              require('@/assets/img/smartworks-central-carousel-slide-one.webp')
            "
            cover
            :eager="true"
            :height="300"
            class="m-carousel__image mb-3 d-block d-md-none"
          >
          </v-img>
        </keep-alive>
        <h3 class="m-carousel__title text-left dms600 mt-0">
          A Serene Oasis Amidst Bustling Kathmandu
        </h3>
        <p class="m-carousel__description text-left m400 mt-3">
          Nestled within a 5-acre property owned by one of Kathmandu's oldest
          nurseries, The Standard Nursery, DIGU Central is strategically located
          in the heart of the valley. We are proud to have integrated our office
          with the lush greenery of the nursery, offering a tranquil escape from
          the hustle and bustle of the city. This unique setting allows our
          employees to recharge and find inspiration amidst nature's splendor.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'MobileSlideOne',
};
</script>
