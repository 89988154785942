<template>
  <v-sheet color="#e3f5ff" height="100%">
    <div class="d-flex flex-column fill-height justify-center align-center">
      <v-row class="section__row" height="100%">
        <v-col
          class="section__col d-flex flex-column fill-height justify-center align-start"
          cols="12"
          sm="12"
          md="12"
          lg="6"
        >
          <h3 class="section__title-2 dms700 mb-4">
            A Place to Collaborate, Network, and Unwind
          </h3>
          <p class="section__description-2 text-left m400">
            We understand the importance of fostering collaboration and building
            connections within our organization. DIGU Central features dedicated
            spaces designed specifically for teamwork and interaction. These
            areas encourage employees to engage in lively discussions,
            brainstorm ideas, and develop innovative solutions. Additionally,
            our recreational area provides a space for relaxation and
            rejuvenation, allowing employees to unwind and recharge their
            creative energies.
          </p>
        </v-col>
        <v-col
          class="section__col d-flex flex-column"
          cols="12"
          sm="12"
          md="12"
          lg="6"
        >
          <keep-alive>
            <v-img
              :src="require('@/assets/img/programs-section.webp')"
              cover
              :eager="true"
              class="carousel__image ma-2"
              :height="400"
            >
            </v-img>
          </keep-alive>
          <caption class="carousel__caption"></caption>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'SlideThree',
};
</script>

<style scoped>
.section__row {
  padding-inline: 6rem !important;
}

.section__subtitle {
  font-size: 2rem;
}

.section__title-2 {
  font-size: 2rem;
  text-align: left;
  line-height: 120%;
}

.carousel__image {
  border-radius: 2rem !important;
}

.carousel__caption {
  width: 100%;
  margin-bottom: 3rem;
}
</style>
