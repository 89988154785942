<template>
  <div class="job-openings">
    <hero-section
      :title="title"
      :showWhiteWave="showWhiteWave"
      :description="description"
      :hasButton="hasButton"
      :buttonText="buttonText"
      :buttonUrl="buttonUrl"
    />
    <div class="section__container">
      <div class="loader" v-if="UIController.loader.contentLoading">
        <v-progress-circular indeterminate></v-progress-circular>
        <p class="mt-5">Hold On! while we fetch the available job openings.</p>
      </div>
      <div v-else>
        <v-card elevation="0" class="error-card" v-if="jobOpenings.length < 1">
          <v-card-title class="error-card__title text-left dms700"
            >No Openings Available</v-card-title
          >
          <v-card-text>
            <h3 class="error-card__text-title text-left m500">
              Looks like there are no openings available at the moment
            </h3>
            <p class="error-card__text text-left m500">
              Why don't you check back in a day or two to see what we have got
              for you.
            </p>
          </v-card-text>
        </v-card>
        <div v-else>
          <v-card
            v-for="jobOpening in jobOpenings"
            :key="jobOpening.id"
            class="job-card"
            elevation="0"
          >
            <v-card-title class="text-left"
              ><v-chip
                label
                size="small"
                :color="jobOpening.jobType.color"
                class="mr-2"
                >{{ jobOpening.jobType.name }}</v-chip
              >
              <v-chip label size="small" :color="jobOpening.category.color">{{
                jobOpening.category.name
              }}</v-chip></v-card-title
            >
            <v-card-text>
              <p class="job-card__title text-left dms500">
                <a
                  :href="`/talents-on-demand/${jobOpening.id}`"
                  class="job-card__link"
                  >{{ jobOpening.name }}</a
                >
              </p>
              <div class="job-card__tags text-left">
                <v-chip
                  v-for="hardSkill in jobOpening.hardSkills"
                  :key="hardSkill.id"
                  :color="hardSkill.color"
                  class="job-card__skill-chip mt-2"
                  size="small"
                >
                  {{ hardSkill.label }}
                </v-chip>
              </div>
              <p class="job-card__description text-left">
                {{ jobOpening.description }}
              </p>
              <v-card-actions>
                <a
                  :href="`/talents-on-demand/${jobOpening.id}`"
                  class="job-card__link link--footer"
                  >Read More ></a
                >
              </v-card-actions>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroSectionVue from '@/components/partials/HeroSection.vue';
import jobOpeningService from '@/services/API/jobOpeningService';
export default {
  name: 'JobOpenings',
  components: {
    'hero-section': HeroSectionVue,
  },
  data: () => ({
    title: 'Talents on Demand',
    description:
      'Join our talent pool. Submit your resume. We will work on your behalf to find your next opportunity.',
    hasButton: true,
    buttonText: 'Submit Your Resume',
    buttonUrl: '/job-application',
    showWhiteWave: true,
    jobOpenings: [],
    UIController: {
      loader: {
        contentLoading: true,
      },
    },
  }),
  async created() {
    document.getElementById('loaderSection').classList.add('d-none');
    this.UIController.loader.contentLoading = true;
    await jobOpeningService.listAllJobOpenings().then((response) => {
      if (response.status === 200) {
        this.jobOpenings = response.data.map((data) => {
          const categoryCF = data.customFields.filter(
            (field) => field.name === 'Category'
          );

          const category =
            categoryCF[0].value != undefined
              ? categoryCF[0].type_config.options[categoryCF[0].value]
              : null;

          const hardSkillsCF = data.customFields.filter(
            (field) => field.name === 'Hard Skills'
          );

          const hardSkills = hardSkillsCF[0].value
            ? hardSkillsCF[0].type_config.options.filter((field) =>
                hardSkillsCF[0].value.includes(field.id)
              )
            : null;

          const jobTypeCF = data.customFields.filter(
            (field) => field.name === 'Job Type'
          );

          const jobType =
            jobTypeCF[0].value != undefined
              ? jobTypeCF[0].type_config.options[jobTypeCF[0].value]
              : null;

          this.UIController.loader.contentLoading = false;

          return {
            id: data.id,
            name: data.name,
            description: data.description,
            category: category,
            hardSkills: hardSkills,
            jobType: jobType,
          };
        });
      } else {
        this.UIController.loader.contentLoading = false;
      }
    });
  },
};
</script>

<style scoped>
.error-card {
  background-color: var(--light-1);
  max-width: 500px;
  margin-inline: auto;
  margin-block: 2rem;
}

.loader {
  padding-block: 5rem;
}

.job-card {
  margin-block: 2rem;
  border-bottom: 1px solid var(--gray-2);
  border-radius: 0px;
}

.job-card:last-child {
  border: none;
}

.job-card__title {
  font-size: 2rem;
  line-height: 1.2;
  margin-block: 1rem;
  color: var(--primary-color-lighten);
}

.job-card__link {
  text-decoration: none;
  color: inherit;
}

.link--footer:hover {
  text-decoration: underline;
}

.job-card__tags {
  margin-block: 0.5rem;
}

.job-card__skill-chip {
  margin-right: 0.5rem;
}

.job-card__description {
  display: block;
  font-size: 0.9rem;
  line-height: 1.5rem;
  max-height: 4.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
</style>
